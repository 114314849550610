<template>
    <div class="planets-info" v-if="horoscope.criteria">
        <div class="planets-table-area">
            <table class="planets-table" width="100%" border="0">
                <thead>
                    <tr>
                        <th colspan="4" align="center">{{ planetsTitle || 'Planets' }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="planet in horoscope.planets.planets" :key="planet.name">
                        <td align="left" width="15%"><b>{{planet.name}} {{planet.retrograde ? "(R)" : ""}}</b></td>
                        <td align="left"  width="20%">
                            <b>{{planet.zodiacSignName}}</b> / <i>{{planet.sanskritZodiacName}}</i><br/>
                        </td>
                        <td align="center"  width="20%">
                            {{planet.relativeDegreeDMS}}<br/>
                        </td>
                        <td align="left"  width="45%">
                            <b>{{planet.englishStarName}}-{{planet.starPada}}</b> / <i>{{planet.tamilStarName}}-{{planet.starPada}}</i>,&nbsp;&nbsp;
                            <b>{{planet.angularVimsottariPeriod.dasa}}</b>,&nbsp;{{planet.angularVimsottariPeriod.bhukti}},&nbsp;
                            {{planet.angularVimsottariPeriod.antaram}},&nbsp;{{planet.angularVimsottariPeriod.sootchumam}}<br/>
                        </td>
                    </tr>
                    <template v-if="horoscope.subPlanets">
                    <tr v-for="planet in horoscope.subPlanets.planets" :key="planet.name">
                        <td align="left" width="15%"><b>{{planet.name}} {{planet.retrograde ? "(R)" : ""}}</b></td>
                        <td align="left"  width="20%">
                            <b>{{planet.zodiacSignName}}</b> / <i>{{planet.sanskritZodiacName}}</i><br/>
                        </td>
                        <td align="center"  width="20%">
                            {{planet.relativeDegreeDMS}}<br/>
                        </td>
                        <td align="left"  width="45%">
                            <b>{{planet.englishStarName}}-{{planet.starPada}}</b> / <i>{{planet.tamilStarName}}-{{planet.starPada}}</i>,&nbsp;&nbsp;
                            <b>{{planet.angularVimsottariPeriod.dasa}}</b>,&nbsp;{{planet.angularVimsottariPeriod.bhukti}},&nbsp;
                            {{planet.angularVimsottariPeriod.antaram}},&nbsp;{{planet.angularVimsottariPeriod.sootchumam}}<br/>
                        </td>
                    </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
	props: {
		horoscope : {
			type: Object
		},
        planetsTitle : {
            type: String
        }
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.planets-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.planets-table-area {
	width: 100%;
}

.planets-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.planets-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.planets-table th {
    padding: 9px 15px;
	font-size: 20px;
}

.planets-table td {
    padding: 8px 15px;
    color: #344E41;
}

.planets-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
