<template>
	<div class="header-area" v-if="horoscope.criteria">
		<div class="client-table">
			<table class="header-table" width="100%" border="0">
				<thead>
					<tr>
						<th colspan="2">{{horoscope.criteria.client || 'Birth Details'}}</th>
					</tr>
				</thead>
				<tbody>
				<tr>
					<td align="left" width="25%"><b>Birth Date</b></td>
					<td align="left">{{horoscope.criteria.longFormattedDate}}, <br/>{{horoscope.criteria.weekday}}</td>
				</tr>
				<tr>	
					<td align="left"><b>Location</b></td>
					<td align="left">{{horoscope.criteria.location}}</td>	
				</tr>	
				<tr>
					<td align="left"><b>Coordinates</b></td>
					<td align="left"><b>Latitude</b>={{horoscope.criteria.latitude}},<br/> <b>Longitude</b>={{horoscope.criteria.longitude}}</td>
				</tr>
				<tr>	
					<td align="left"><b>UTC Offset</b></td>
					<td align="left">{{horoscope.criteria.timeOffset * 60}} minutes</td>	
				</tr>
				<tr>	
					<td align="left"><b>Timezone</b></td>
					<td align="left">{{horoscope.criteria.timeOffsetString}}</td>	
				</tr>
				</tbody>
			</table>
		</div>
        <div class="edit-birth-details" align="center">
          <button @click="activateMenuItem()">&nbsp;Edit Chart Details&nbsp;</button>&nbsp;
        </div>
	</div>
</template>

<script>


export default {
	props: {
		criteria : {
			type: Object
		},
		horoscope: {
			type: Object
		}
	},
	data() {
		return {
			pdfUrl: ""
		}
	},
	methods: {
		activateMenuItem() {
			this.$emit('activeMenuItem', 'home');
		}
	}
}
</script>

<style scoped>

.header-area {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.client-table {
	width: 100%;
}

.edit-birth-details {
	margin-top: 10px;
	margin-bottom: 10px;
}

button {
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

.header-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.header-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.header-table th {
    padding: 12px 15px;
	font-size: 20px;
}

.header-table td {
    padding: 12px 15px;
    color: #344E41;
}

.header-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
