<template>
    <div class="screen-wrapper">

        <div class="vimsottri-dasa" v-if="dasaList">
            <table class="dasa-table" cellspacing="0" cellpadding="0" border="0" width="95%">
                <col style="width:12%">
                <col style="width:12%">
                <col style="width:12%">
                <col style="width:16%">
                <col style="width:16%">
                <col style="width:16%">
                <col style="width:16%">
                <thead>
                    <tr>
                        <th align="left">Dasa /<br/> Maha Dasa</th>
                        <th align="left">Bhukti /<br/> Antar Dasa</th>
                        <th align="left">Antara /<br/> Pratyantar Dasa</th>
                        <th align="center">Start Date</th>
                        <th align="center">End Date</th>
                        <th align="right">Age</th>
                        <th align="right">Duration</th>
                    </tr>
                </thead>
                <tbody v-for="dasa in dasaList" :key="dasa.order">
                    <tr @click="toggleDasa(dasa.lord)" v-if="dasa.current && selectedDasa==dasa.lord" style="background:#FFFF66">
                        <td align="left"><b>{{dasa.lord}}</b></td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                        <td align="right">{{dasa.startAge}}</td>
                        <td align="right">{{dasa.period}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="dasa.current" style="background:#FFFF66">
                        <td align="left">{{dasa.lord}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                        <td align="right">{{dasa.startAge}}</td>
                        <td align="right">{{dasa.period}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="dasa.beforeBirth" style="background:#DDD">
                        <td align="left">{{dasa.lord}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                        <td align="right">{{dasa.startAge}}</td>
                        <td align="right">{{dasa.period}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="selectedDasa==dasa.lord">
                        <td align="left"><b>{{dasa.lord}}</b></td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                        <td align="right">{{dasa.startAge}}</td>
                        <td align="right">{{dasa.period}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else>
                        <td align="left">{{dasa.lord}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                        <td align="right">{{dasa.startAge}}</td>
                        <td align="right">{{dasa.period}}</td>
                    </tr>
                    <tr v-if="selectedDasa==dasa.lord && bhuktiList">
                        <td colspan="8">
                            <table class="bukti-table" cellspacing="0" cellpadding="0" border="0">
                                <col style="width:12%">
                                <col style="width:12%">
                                <col style="width:12%">
                                <col style="width:16%">
                                <col style="width:16%">
                                <col style="width:16%">
                                <col style="width:16%">
                                <tbody v-for="bukti in bhuktiList" :key="bukti.order">
                                    <tr @click="toggleBukti(bukti.lord)" v-if="bukti.current && selectedBukti==bukti.lord" style="background:#FFFF66">
                                        <td align="left"><b>{{dasa.lord}}</b></td>
                                        <td align="left"><b>{{bukti.lord}}</b></td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                        <td align="right">{{bukti.startAge}}</td>
                                        <td align="right">{{bukti.period}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="bukti.current" style="background:#FFFF66">
                                        <td align="left">{{dasa.lord}}</td>
                                        <td align="left">{{bukti.lord}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                        <td align="right">{{bukti.startAge}}</td>
                                        <td align="right">{{bukti.period}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="bukti.beforeBirth" style="background:#DDD">
                                        <td align="left">{{dasa.lord}}</td>
                                        <td align="left">{{bukti.lord}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                        <td align="right">{{bukti.startAge}}</td>
                                        <td align="right">{{bukti.period}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="selectedBukti==bukti.lord">
                                        <td align="left"><b>{{dasa.lord}}</b></td>
                                        <td align="left"><b>{{bukti.lord}}</b></td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                        <td align="right">{{bukti.startAge}}</td>
                                        <td align="right">{{bukti.period}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else>
                                        <td align="left">{{dasa.lord}}</td>
                                        <td align="left">{{bukti.lord}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                        <td align="right">{{bukti.startAge}}</td>
                                        <td align="right">{{bukti.period}}</td>
                                    </tr>
                                    <tr v-if="selectedBukti==bukti.lord && antaramList">
                                        <td colspan="8">
                                            <table class="antaram-table" cellspacing="0" cellpadding="0" border="0">
                                                <col style="width:12%">
                                                <col style="width:12%">
                                                <col style="width:12%">
                                                <col style="width:16%">
                                                <col style="width:16%">
                                                <col style="width:16%">
                                                <col style="width:16%">
                                                <tbody v-for="antaram in antaramList" :key="antaram.order">
                                                    <tr v-if="antaram.current" style="background:#FFFF66">
                                                        <td align="left"><b>{{dasa.lord}}</b></td>
                                                        <td align="left"><b>{{bukti.lord}}</b></td>
                                                        <td align="left"><b>{{antaram.lord}}</b></td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                        <td align="right">{{antaram.startAge}}</td>
                                                        <td align="right">{{antaram.period}}</td>
                                                    </tr>
                                                    <tr v-else-if="antaram.beforeBirth" style="background:#DDD">
                                                        <td align="left">{{dasa.lord}}</td>
                                                        <td align="left">{{bukti.lord}}</td>
                                                        <td align="left">{{antaram.lord}}</td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                        <td align="right">{{antaram.startAge}}</td>
                                                        <td align="right">{{antaram.period}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td align="left">{{dasa.lord}}</td>
                                                        <td align="left">{{bukti.lord}}</td>
                                                        <td align="left">{{antaram.lord}}</td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                        <td align="right">{{antaram.startAge}}</td>
                                                        <td align="right">{{antaram.period}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/util.js'
import HTTP from '@/http-axios';

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
            dasaList: {},
            bhuktiList: {},
            antaramList: {},
            selectedDasa: '',
            selectedBukti: '',
            pageLoading: false
		}
	},
	methods: {
        toggleDasa( dasaClicked ) {
            if ( this.selectedDasa == dasaClicked ) {
                this.selectedDasa = ''
                this.selectedBukti = ''
            } else {
                this.selectedDasa = dasaClicked
                this.selectedBukti = ''
                this.bhuktiList = {};
                this.getBhukti()
            }
        },
        toggleBukti( buktiClicked ) {
            if ( this.selectedBukti == buktiClicked ) {
                this.selectedBukti = ''
            } else {
                this.selectedBukti = buktiClicked
                this.antaramList = {};
                this.getAntaram()
            }
        },
		queryString: function( obj ) {
			return queryString(obj);
		},
		getDasa: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.dasaList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
		getBhukti: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?filterDasa=' + 
                            this.selectedDasa + 
                            '&' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.bhuktiList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
		getAntaram: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?filterDasa=' + 
                            this.selectedDasa + 
                            '&filterBhukti=' + 
                            this.selectedBukti + 
                            '&' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.antaramList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
	},
	created() {
		this.getDasa()
	},
}
</script>

<style scoped>

.screen-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

.vimsottri-dasa {
    border: 0px solid;
    width: 75%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dasa-table {
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
    background-color: #FFFFFF;
}

.dasa-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
}   

.dasa-table th {
    height: 30px;
    padding: 5px;
}

.dasa-table td {
    height: 30px;
    color: #344E41;
    cursor: pointer;
    padding: 0px;    
}

.dasa-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.dasa-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.bukti-table {
    width: 100%;
    border-collapse: collapse;
    font-family: sans-serif;
    overflow: hidden;
}

.bukti-table td {
    color: #344E41;
    cursor: pointer;
}

.bukti-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.bukti-table tbody tr:nth-of-type(odd) {
    background-color: #EDF6F9;
}

.antaram-table {
    width: 100%;
    border-collapse: collapse;
    font-family: sans-serif;
    overflow: hidden;
}

.antaram-table td {
    color: #344E41;
    cursor: default;
}

.antaram-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.antaram-table tbody tr:nth-of-type(odd) {
    background-color: #e6ffe6;
}

.current {
    background-color: #ffff66;
}

@media screen and (max-width: 1205px) {

    .screen-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }

    .vimsottri-dasa {
        border: 0px solid;
        width: 95%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }


}

</style>
