<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Our platform grants you the freedom to journey through the cosmos and witness the dynamic interplay of Vedic planets at your chosen date and time. 
			Whether you're curious about the planetary alignments at your birth, seeking insight into current transits, or planning auspicious moments for important events, our tool offers precise positions.		
		</h3>
	</div>

	<div class="screen-wrapper">

		<div class="desktop-planetary-positions" v-if="deviceType=='BS'">
			<PlanetaryPositions />
		</div>

		<div class="mobile-planetary-positions" v-if="deviceType=='SS'">
			<MobilePlanetaryPositions />
		</div>

	</div>


</template>

<script>
import PlanetaryPositions from '@/components/PlanetaryPositions'
import MobilePlanetaryPositions from '@/components/MobilePlanetaryPositions'

export default {
	components: { PlanetaryPositions, MobilePlanetaryPositions },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-planetary-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-planetary-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

</style>
