<template>
		  <WorldCitySearchWidget 
        :searchMode="searchMode" 
        :criteria="criteria" 
        :textFieldSize="textFieldSize" />
</template>

<script>

import WorldCitySearchWidget from '@/components/widget-city-search/WorldCitySearchWidget'

export default {
  components: {WorldCitySearchWidget},
	props: {
		criteria : {
			type: Object
		},
    textFieldSize : {
        default: 300,
        type: Number
    },
    searchMode: {
        type: String,
        default: 'fallback'
    }
  },
	data() {
    return {
      searchModeOverride: ''
    }
	},
	created() {
    // this.setSearchModeOverride();
	},	
	methods: {
    setSearchModeOverride() {
        var today = new Date();
        var hours = today.getUTCHours();            
        var cell = hours % 2;
        this.searchModeOverride = (cell == 0 ? 'google' : 'fallback')
        console.log('today: ' + today)
        console.log('hours: ' + hours)
        console.log('mod: ' + cell)
        console.log('search-mode: ' + this.searchModeOverride)
		}		
	}
}
</script>

<style>

</style>