<template>
	<div class="dvc-screen-wrapper">
		<div class="chart-area" v-if="horoscope.criteria">
			<div class="chart-area-1">
				<DivisionalChartImage 
					:chartImageId="id + '1'" 
					:criteria="horoscope.criteria" 
					:initialChartType="'D1'" 
					:showChartTypeConfig="true" 
 					:chartImageTitle="horoscope.criteria.dateInfo" />
			</div>
			<div class="chart-area-2">
				<DivisionalChartImage 
					:chartImageId="id + '2'" 
					:criteria="horoscope.criteria" 
					:initialChartType="'D9'" 
					:showChartTypeConfig="true" 
					:chartImageTitle="horoscope.criteria.dateInfo" />
			</div>
			<div class="chart-area-3">
				<DivisionalChartImage 
					:chartImageId="id + '3'" 
					:criteria="horoscope.criteria" 
					:initialChartType="'D10'" 
					:showChartTypeConfig="true" 
					:chartImageTitle="horoscope.criteria.dateInfo" />
			</div>
		</div>

        <div class="varga-table" align="center" v-if="horoscope.vargaTable">
			<table class="varga-table-style" cellspacing="0" cellpadding="0" border="0">
				<thead>
					<tr>
						<th align="center">&nbsp;&nbsp;</th>
						<th align="center">D1</th>
						<th align="center">D2</th>
						<th align="center">D3</th>
						<th align="center">D4</th>
						<th align="center">D5</th>
						<th align="center">D6</th>
						<th align="center">D7</th>
						<th align="center">D8</th>
						<th align="center">D9</th>
						<th align="center">D10</th>
						<th align="center">D11</th>
						<th align="center">D12</th>
						<th align="center">D16</th>
						<th align="center">D20</th>
						<th align="center">D24</th>
						<th align="center">D27</th>
						<th align="center">D30</th>
						<th align="center">D40</th>
						<th align="center">D45</th>
						<th align="center">D60</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(planetVargaPositions, index) in horoscope.vargaTable" v-bind:key="index">
						<td align="center" v-for="(planetSign, index) in planetVargaPositions" v-bind:key="index">
							<template v-if="index == 0">
								<b>{{planetSign}}</b>
							</template>
							<template v-else>
								{{planetSign}}
							</template>
						</td>
					</tr>
				</tbody>
			</table>
        </div>

	</div>
</template>

<script>
import DivisionalChartImage from '@/components/natal-chart/DivisionalChartImage.vue'
import ShowPlanetsTable from '@/components/ShowPlanetsTable.vue'

export default {

	components: { DivisionalChartImage, ShowPlanetsTable },
	props: {
        id: {
            type: String
        },
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {

	}
}
</script>

<style scoped>

.dvc-screen-wrapper {
  border: 0px solid;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.chart-area {
	border: 0px solid;
	display:flex;
	justify-content:space-evenly; 
	width:95%;
	margin-top:10px
}

.varga-table {
  margin: 15px;
  width: 95%;
  margin-top: 30px;
}

.varga-table-style {
    width: 70%;
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
}

.varga-table-style thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
}

.varga-table-style th {
    border: 1px solid black;
    height: 30px;
    padding-left: 7px;
	padding-right: 7px;
}

.varga-table-style td {
    border: 1px solid black;
    height: 30px;
    color: #344E41;
    padding-left: 7px;
	padding-right: 7px;
}

.varga-table-style tbody tr {
    border-bottom: 1px solid #dddddd;
}

.varga-table-style tbody tr:nth-of-type(even) {
    background-color: #EDF6F9;
}

@media screen and (max-width: 1599px) {

	.chart-area {
		border: 0px solid;
		display:flex;
		flex-direction: column;
		align-items: center;
		width:95%;
		margin-top:10px
	}

	.chart-area-2 {
		margin-top:40px
	}

	.chart-area-3 {
		margin-top:40px
	}

}
	
</style>
