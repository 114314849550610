<template>
	<div class="cp-screen-wrapper" v-if="criteria">
		<div class="chart-image-area" v-if="transitInfo.chartImages">
			<div class="chart-image-title">
				<h2>Current Planetary Positions</h2>
			</div>
			<div class="chart-image-1">
				<img :src="transitInfo.chartImages.imageMap.D1" alt="D1">
			</div>
			<div class="chart-image-title">
				{{now.toString().indexOf("(") > 0 ? now.toString().substring(0, now.toString().indexOf("(")) : now.toString()}}
			</div>
			<div class="chart-image-2">
				<img :src="transitInfo.chartImages.imageMap.D9" alt="D9">
			</div>	
			<div class="chart-image-title">
				{{now.toString().indexOf("(") > 0 ? now.toString().substring(0, now.toString().indexOf("(")) : now.toString()}}
			</div>
			<div class="planets-section" align="center" v-if="transitInfo.planets">
				<ShowPlanetsTable :horoscope="transitInfo" :planetsTitle="'Current Planetary Positions'" />
			</div>
		</div>
	</div>
</template>

<script>

import HTTP from '@/http-axios';
import { queryString } from '@/util.js'
import ShowPlanetsTable from '@/components/ShowPlanetsTable.vue'

export default {
	components: { ShowPlanetsTable },
	props: {
		criteria : {
			type: Object
		}
	},
	data() {
		return {
			chartTheme: 2,
			chartSize: 2,
			transitInfo: {},
			now: new Date()
		}
	},
	created() {
		this.getTransitInfo(this.getCurrentPlanetaryPositionsCriteria())
	},
	methods: {
		getCurrentPlanetaryPositionsCriteria: function() {
			var transitCriteria = {
				year: this.now.getFullYear(),
				month: this.now.getMonth() + 1,
				day: this.now.getDate(),
				hour: this.now.getHours(),
				minute: this.now.getMinutes(),
				second: this.now.getSeconds(),
				location: this.criteria.location,
				latitude: this.criteria.latitude,
				longitude: this.criteria.longitude,
				timeOffset: this.criteria.timeOffset,
				client: 'CPP-' + this.criteria.client,
				chartStyle: this.criteria.chartStyle,
				chartTheme: this.chartTheme,
				chartSize: this.chartSize
			}
			return transitCriteria;
		},
		queryString: function( obj ) {
			return queryString(obj);
		},
		getTransitInfo: function(transitCriteria) {
			HTTP().post(`/api/data/transit?` + this.queryString(transitCriteria)).then(response => {
				this.transitInfo = response.data
			})
			.catch(e => {
				console.log(e)
			})
		},
	},
	beforeUnmount() {	
		
	}
}
</script>

<style scoped>

.cp-screen-wrapper {
  border: 0px solid;
  width: 100%;
}

.chart-image-area {
	border: 0px solid;
	display: block;
	float: left;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
	margin-top:10px
}

.chart-image-1 {
	padding: 10px;
}

.chart-image-2 {
	padding: 10px;
}

.chart-image-title {
    font-size: 0.9em;
    font-family: sans-serif;
    color: #344E41;
    text-align: left;
    font-weight: bold;
	margin: 5px auto;
}

.planets-section {
	margin: 5px;
	width: 100%;
	margin: 20px;
}

</style>
