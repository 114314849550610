<template>
  <div id="app">

    <template v-if="$route.name">

      <SEOStructuredData  v-if="$route.name && !new String($route.name).startsWith('natal-chart-widget') && !new String($route.name).startsWith('ncw-')" />

      <TopNavBar v-if="$route.name && !new String($route.name).startsWith('ncw-')"
      :nav-links="navLinks"
      background="#344E41"
      link-color="#FFFFFF"
      hoverBackground="#FF9F1C"
      />

    </template>
    
    <router-view />
    
  </div>
</template>

<script>

import SEOStructuredData from '@/views/SEOStructuredData'
import TopNavBar from '@/views/TopNavBar'

export default {
  components: {
    SEOStructuredData,
    TopNavBar
  },
  data: () => ({
    navLinks: [
    {
      text: 'Natal Chart',
      path: '/natal-chart',
      icon: 'fas fa-file-alt fa-lg',
      fawIcon: ['fas', 'file-lines'],
    },
    {
      text: 'Planetary Positions',
		  path: '/planetary-positions',
		  icon: 'fa fa-globe-americas fa-lg',
      fawIcon: ['fas', 'earth-americas'],
    },
    {
      text: 'Transits',
		  path: '/transits',
		  icon: 'fas fa-spinner fa-lg',
      fawIcon: ['fas', 'arrows-rotate'],
    },
    {
      text: 'Panchanga',
		  path: '/panchanga',
		  icon: 'far fa-calendar-alt fa-lg',
      fawIcon: ['far', 'calendar-alt']
    },
    {
      text: 'Jamakkol Horary',
		  path: '/jamakkol',
		  icon: 'fa fa-hour-glass fa-lg',
      fawIcon: ['far', 'hourglass'],
    },
    {
       text: 'Widgets',
		   path: '/widgets',
		   icon: 'fas fa-link fa-lg',
       fawIcon: ['fas', 'link']
     },
    // {
    //   text: 'Blog',
		//   path: '/blog',
		//   icon: 'fab fa-blogger fa-lg',
    //   fawIcon: ['fab', 'blogger']
    // },
    {
      text: 'FAQ',
		  path: '/faq',
		  icon: 'fas fa-question-circle fa-lg',
      fawIcon: ['fas', 'circle-question']
    },
    {
      text: 'Contact',
		  path: '/contact',
		  icon: 'fas fa-envelope fa-lg',
      fawIcon: ['fas', 'envelope']
    }
    ]
  })
}
</script>

<style lang="scss">

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  margin: 0;
  background: #FBFBFF;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
