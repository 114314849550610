<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Unlock the secrets of your cosmic blueprint with our personalized natal chart feature.
			On our website, you have the power to generate detailed charts that map out the positions of the planets at the moment of their birth.
			This insightful tool offers a window into the unique cosmic energies influencing your life,
			providing valuable insights into planetary strength, aspects and conjunctions, divisional charts, and Vimsottari Dasa periods.
		</h3>
	</div>

	<div class="screen-wrapper">
		<!-- DESKTOP -->
		<div class="desktop-chart-criteria" v-if="menuItem == 'home' && deviceType == 'BS'">
			<ChartCriteria @criteria="setCriteria" />
		</div>

		<div class="desktop-natal-chart-area" v-if="menuItem !== 'home' && deviceType == 'BS'">
			<NatalChart :id="'nnc'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

		<!-- MOBILE -->
		<div class="mobile-chart-criteria" v-if="menuItem == 'home' && deviceType == 'SS'">
			<MobileChartCriteria @criteria="setCriteria" />
		</div>

		<div class="mobile-natal-chart-area" v-if="menuItem !== 'home' && deviceType == 'SS'">
			<MobileNatalChart :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

	</div>


</template>

<script>
import ChartCriteria from '@/components/ChartCriteria'
import MobileChartCriteria from '@/components/MobileChartCriteria'
import NatalChart from '@/components/natal-chart/NatalChart'
import MobileNatalChart from '@/components/small-screen-natal-chart/MobileNatalChart'


export default {
	components: { ChartCriteria, MobileChartCriteria, NatalChart, MobileNatalChart },
	data() {
		return {
			menuItem: 'home',
			criteria: {},
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},
	methods: {
		setCriteria( criteria ) {
			this.criteria = criteria
			this.menuItem = 'charts'
		},		
		activateMenuItem( activeMenuItem ) {
			this.menuItem = activeMenuItem;
		},
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
}
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.mobile-chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.desktop-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.mobile-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

</style>
