<template>
  <div class="sell-cnc-wrapper">
    <div class="sell-cnc-content">
      <div class="vedic-chart-desc">
        <h2><u>Complete Natal Chart</u>&nbsp;&nbsp;&nbsp;<u>${{orderPrice}}</u></h2>
        <p>
          Your complete natal chart includes:
        <ul>
          <li>Planetary Positions</li>
          <li>Planetary Strength</li>
          <li>Planetary Aspects</li>
          <li>Planetary Conjunctions</li>
          <li>House Cusps and Midpoints</li>
          <li>20 Divisional Chart Images</li>
          <li>Vimsottari Dasa Periods</li>
          <li>Natal Panchanga (Vedic Calendar)</li>
          <li>Instant <b>PDF</b> document download &amp; delivery to your email</li>
        </ul>
        </p>
      </div>
      <!--
      <div class="sample-chart-image">
        <img width="285" height="403" src="../../assets/sample-complete-natal-chart.png" 
          alt="Sample Complete Natal Chart" 
          title="View Sample Complete Natal Chart"
          @click="showSampleNatalChart()" />
      </div>
      -->

      <div class="sample-chart-image">
        <button 
          title="View Sample Complete Natal Chart"
          @click="showSampleNatalChart()">View Sample Chart</button>
      </div>

    </div>
	<div class="stripe-container" v-if="!orderCompleted">
		<div class="apple-pay" @click="stripePurchase('apple-pay')"><img src="../../assets/apay.png" alt="Apple Pay" width="80" /></div>
		<div class="google-pay" @click="stripePurchase('google-pay')"><img src="../../assets/gpay.png" alt="Apple Pay" width="80" /></div>
		<div class="credit-cards" @click="stripePurchase('credit-cards')"><img src="../../assets/stripe-credit-cards.png" alt="Apple Pay" width="125" /></div>
	</div>
    <div class="paypal-container" ref="paypalContainer" v-if="!orderCompleted"></div>
    <div id="transactionStatus" width="50%" align="center" v-if="orderCompleted">
      <h3>Thank you for your order, {{paypalOrder.payerGivenName}} {{paypalOrder.payerSurname}}.</h3>
      <h4><button @click="downloadCompleteChartPDF()">&nbsp;Download Chart&nbsp;&nbsp;<i class="far fa-file-pdf fa-lg"></i></button></h4>
      <h4>Your complete natal chart is also delivered to <font color="green"><b>{{paypalOrder.payerEmail}}</b></font>.</h4>
    </div>
  </div>
</template>

<script>
import HTTP from '../../http-axios';
import ApiBaseUrl from '../../http-api-base-url'
import { queryString, generatePaidNatalChartKey } from '@/util.js'

export default {
  props: {
    criteria: {
      type: Object
    }
  },
  data() {
    return {
      orderDescription: 'Complete Natal Chart from www.vedicastrochart.com',
      orderPrice: '4.95',
      orderCompleted: false,
      paypalOrder: {
        orderId: '',
        payerGivenName: '',
        payerSurname: '',
        payerEmail: '',
        orderData: '',
        pdfUrl: '',
      }
    }
  },
  created() {
    this.paypalOrder.pdfUrl = ApiBaseUrl() + '/api/pdf/natalchart?' + this.queryString(this.criteria) + '&ct=' + this.getCriteriaToken(this.criteria);
  },
  mounted() {

    const script = document.createElement("script");

    // SANDBOX - TESTING
    //script.src = "https://www.paypal.com/sdk/js?client-id=Af_ir11mM_o7A22aqOu2azj9FSXrfO2h_xjOeLP0hLgv0LR93ddjRAqxtQoVcYUJ_PMgP2lubzB3isGI&currency=USD&disable-funding=credit,card";

    // PRODUCTION - LIVE
    script.src = "https://www.paypal.com/sdk/js?client-id=AdmcXR3kme4bPK1lX05Gx5J03DCwudEhCnKPDU23FRNDbiz8ReoYPDIaGH68T828IthtIqzwFDjxcPkr&currency=USD&disable-funding=credit,card";

    script.addEventListener("load", this.setLoaded)
    document.body.appendChild(script)
  },
  methods: {

    setLoaded() {
      paypal.Buttons({
        // Sets up the transaction when a payment button is clicked
        createOrder: (data, actions) => {
          this.orderCompleted = false;
          return actions.order.create({
            purchase_units: [{
              description: this.orderDescription,
              amount: {
                value: this.orderPrice,
                currency_code: "USD"
              }
            }],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            }
          });
        },
        // Finalize the transaction after payer approval
        onApprove: (data, actions) => {

          const orderHandler = (orderData) => {
            var orderJsonString = JSON.stringify(orderData, null, 2);
            console.log('Capture result', orderData, orderJsonString);
            this.purchaseSuccess(orderData.id, orderData.payer.name.given_name, orderData.payer.name.surname, orderData.payer.email_address, orderJsonString);
          };

          return actions.order.capture().then(orderHandler);
        },

        onError: (err) => {
          console.log(JSON.stringify(err, null, 2));
          this.purchaseFailure(JSON.stringify(err, null, 2))
        }

      }).render(this.$refs.paypalContainer);

    },

    purchaseSuccess: function (orderId, payerGivenName, payerSurname, payerEmail, orderJsonString) {

      // Set paypal order details
      this.paypalOrder.orderId = orderId;
      this.paypalOrder.payerGivenName = payerGivenName;
      this.paypalOrder.payerSurname = payerSurname;
      this.paypalOrder.payerEmail = payerEmail;
      this.paypalOrder.orderData = orderJsonString;

      // Track paypal order success
      HTTP().post(`/api/purchase/natalchart/complete/success?` + this.queryString(this.criteria) + '&' + this.queryString(this.paypalOrder))
        .then(response => {
          console.log('order success:');
          this.orderCompleted = true;
        })
        .catch(e => {
          console.log(e)
          this.orderCompleted = false;
        })
    },

    purchaseFailure: function (errorMessage) {

      this.orderCompleted = false;

      // Track paypal order failure
      HTTP().post(`/api/purchase/natalchart/complete/failure?` + this.queryString(this.criteria) + '&error=' + errorMessage + '&pdfUrl=' + this.paypalOrder.pdfUrl)
        .then(response => {
          console.log('order failure:');
        })
        .catch(e => {
          console.log(e)
          this.orderCompleted = false;
        })
    },

    downloadCompleteChartPDF() {
      HTTP().get(ApiBaseUrl() + '/api/pdf/natalchart?' + this.queryString(this.criteria) + '&ct=' + this.getCriteriaToken(this.criteria), {
          responseType: 'blob'
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/pdf'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = new String("VedicAstroChart" + (this.criteria.client ? " - " + this.criteria.client : ""))
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },

    queryString: function (obj) {
        return queryString(obj);
    },

    getCriteriaToken: function() {
        return generatePaidNatalChartKey(this.criteria);
    },

	stripePurchase: function(paymentMethod) {
      HTTP().post(`/api/purchase/natalchart/stripe/order/checkout?` + this.queryString(this.criteria) + '&pm=' + paymentMethod)
        .then(response => {
		      window.location.href = response.data	
        })
        .catch(e => {
          console.log(e)
        })
	},
  showSampleNatalChart() {
      window.open(ApiBaseUrl() + '/api/pdf/sample/natalchart', "_blank");
  },  
  }

}

</script>

<style scoped>

body {
	font-family: Arial, Helvetica, sans-serif;
}

h3 {
	margin: 25px 0 0;
}

.natal-chart-link {
	border-radius: 5px;
	background-color: #087F8C;
	color: #FFFFFF;
	font-weight: bold;
	font-size: 20px;
	padding: 12px;
	text-decoration: none;
}

li {
	text-align: left;
	padding: 5px;
}

p {
	color: #344E41;
	text-indent: 10px;
	text-align: left;
	padding: 10px;
}

.sell-cnc-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	justify-content: center;
	padding-top: 50px;
}

.sell-cnc-content {
	border: 1px solid;
	border-color: #a0a0a0;
	align-content: center;
	background: #EDF6F9;
	border-radius: 10px;
	margin-bottom: 10px;

	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.natal-chart-button-area {
	width: 50%;
	border: 0px solid;
	padding: 30px;
	cursor: pointer;
}

.sample-chart-image {
	border: 0px solid;
	width: 35%;
	padding: 10px;
  cursor: pointer;
}

.vedic-chart-desc {
	width: 65%;
	padding: 5px;
}

button {
	background-color: #087F8C;
	color: #FFFFFF;
	font-weight: bold;
	border: none;
	padding: 11px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
	text-align: center;
}

.stripe-container {
	border: 0px solid;
	width: 90%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.apple-pay {
	padding: 10px;
	cursor: pointer;
}

.google-pay {
	padding: 10px;
	cursor: pointer;
}

.creditc-cards {
	padding: 10px;
	cursor: pointer;
}

.paypal-container {
	border: 0px solid;
	padding: 10px;
	cursor: pointer;
	width: 50%;
}


@media screen and (max-width: 1205px) {

	p {
		color: #344E41;
		text-indent: 10px;
		text-align: left;
		padding: 10px;
	}

	h3 {
		margin: 25px 0 0;
	}

	li {
		text-align: left;
		padding: 3px;
		list-style-position: outside;
	}

	.sell-cnc-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		border-radius: 10px;
		padding-top: 10px;
	}

	.sell-cnc-content {
		border: 1px solid;
		border-color: #a0a0a0;
		align-content: center;
		background: #EDF6F9;
		border-radius: 10px;
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 5px;
	}

	.natal-chart-button-area {
		width: 100%;
		border: 0px solid;
		padding: 30px;
		cursor: pointer;
	}

	.sample-chart-image {
		border: 0px solid;
		width: 100%;
		padding: 10px;
		cursor: pointer;
	}

	.vedic-chart-desc {
		width: 100%;
		padding: 5px;
	}

	button {
		background-color: #087F8C;
		color: #FFFFFF;
		font-weight: bold;
		border: none;
		padding: 11px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		cursor: pointer;
		border-radius: 5px;
		outline: none;
		text-align: center;
	}

}

</style>