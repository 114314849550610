<template>
	<div class="aspects-info" v-if="horoscope.criteria">
		<div class="aspects-table-area">
			<table class="aspects-table" width="100%" border="0">
				<thead>
					<tr>
						<th colspan="2">Aspects</th>
					</tr>
				</thead>
				<tbody>
				<tr v-for="planet in horoscope.planets.planets" :key="planet.name">
					<template v-if="planet.planetAttributes.aspectingObjects">
					<td align="left" width="20%"><b>{{planet.name}}</b></td>
					<td align="left">{{planet.planetAttributes.formattedAspectObjects}}</td>	
					</template>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.aspects-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.aspects-table-area {
	width: 100%;
}

.aspects-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.aspects-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.aspects-table th {
    padding: 12px 15px;
	font-size: 20px;
}

.aspects-table td {
    padding: 12px 15px;
    color: #344E41;
}

.aspects-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
