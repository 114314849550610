<template>

    <div class="preHeader">
      <h3 style="color:#FBFBFF">
        Need further assistance or have specific inquiries? 
        Don't hesitate to reach out to us via our Contact Us page. 
        Whether you have questions about our astrology tools, need technical support, or seek guidance on interpreting your astrological data, we're here to help. 
      </h3>
    </div>

    <div class="about-wrapper">
        <h3>Contact Us</h3>
        <div class="message-fields">
            <label for="fname">Your Name</label>
            <input type="text" id="name" name="name" placeholder="Name (Optional)" @focus="status=' '"
              maxlength="60" v-model="userMessage.name">
            <label for="lname">Your Email</label>
            <input type="text" id="email" name="email" placeholder="Email (Optional)" @focus="status=' '"
              maxlength="60" v-model="userMessage.email">
            <label for="lname">Subject</label>
            <input type="text" id="subject" name="subject" placeholder="Subject (Optional)" @focus="status=' '"
              maxlength="120" v-model="userMessage.subject">
            <label for="subject">Message</label>
            <textarea id="message" name="message" placeholder="Your message..." @focus="status=' '"
              style="height:200px" maxlength="1000" v-model="userMessage.message"></textarea>
            <div class="message-sent-response" v-if="status">&nbsp;{{status}}&nbsp;</div>
        </div>
        <div class="button-fields">
          <button @click="sendMessage">&nbsp;Send Message&nbsp;</button>
        </div>

    </div>
</template>

<script>
import HTTP from '../http-axios';
import { queryString, isBlank } from '../util.js'
export default {
	data() {
		return {
			userMessage: {
          name:     ( sessionStorage.getItem("cuname") ? sessionStorage.getItem("cuname") : ''),
          email:    ( sessionStorage.getItem("cuemail") ? sessionStorage.getItem("cuemail") : ''),
          subject:  ( sessionStorage.getItem("cusubject") ? sessionStorage.getItem("cusubject") : ''),
          message:  ( sessionStorage.getItem("cumessage") ? sessionStorage.getItem("cumessage") : ''),
      },
      status: ' '
		}
	},
	created() {
		
	},
	methods: {
    queryString: function( obj ) {
      return queryString(obj);
    },
		sendMessage: function() {
      if ( isBlank(this.userMessage.message) ) {
        // can not submit if no message is entered
        this.status = "Please enter your message";
      } else {
        this.status = "Sending your message...";
        HTTP().post(`/api/contact/message?` + this.queryString(this.userMessage))
        .then(response => {
          if ( response.data == "OK") {
            this.status = "Your message is sent sucessfully";
            this.userMessage.name = ""
            this.userMessage.email = ""
            this.userMessage.subject = ""
            this.userMessage.message = ""
          } else {
            this.status = "Unable to send your message. Please try again later";
          }

        })
        .catch(e => {
          console.log(e)
        })
      }
    },
	},
  beforeUnmount() {
    // Capture message fields before leaving the component
    sessionStorage.setItem("cuname", this.userMessage.name)
    sessionStorage.setItem("cuemail", this.userMessage.email)
    sessionStorage.setItem("cusubject", this.userMessage.subject)
    sessionStorage.setItem("cumessage", this.userMessage.message)
  }
}
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
  border-radius: 10px;
  justify-content: center;
}

.message-fields {
  border: 1px solid ;
  border-color: #a0a0a0;
  border-radius: 5px;
  background-color: #EDF6F9;
  padding: 20px;
  width: 50%;
  text-align: left;
}

.button-fields {
  text-align: center;
  padding: 20px;
}

.message-sent-response {
  border: 0px solid ;  
  text-align: center;
}

body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

input[type=text], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  outline: none;
  color: #344E41  ;
}

textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  outline: none;
  color: #344E41  ;
}

input[type=text]:focus {
  border: 2px solid #ACD8AA;
}

textarea:focus {
  border: 2px solid #ACD8AA;
}

button {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

@media screen and (max-width: 1201px) {

  .about-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
  }

  .message-fields {
    border: 1px solid ;
    border-color: #a0a0a0;
    border-radius: 5px;
    background-color: #EDF6F9;
    padding: 10px;
    width: 90%;
    text-align: left;
  }

}

</style>