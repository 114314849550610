<template>
<div class="screen-wrapper">
   <div class="chart-criteria-wrapper">
      <div class="header-section" align="center" @click="gotoVAC()" title="Opens in new window">
         <table width="100%" border="0">
            <tr>
               <td width="100%" align="center">&nbsp;<img src="./../../assets/logo.png" width="157" height="45" /></td>
            </tr>
         </table>
      </div>
      <div class="input-section">
         <table width="100%" border="0" cellpadding="5">
            <tr title="Birth Location">
               <td width="100%" align="left">
                  <WorldCitySearch :textFieldSize="310" :criteria="criteria" @selectedWorldCity="setWorldCity"></WorldCitySearch>
               </td>
            </tr>
            <tr title="Birth Date">
               <td width="100%"  align="left">
                  <select name="month" v-model="criteria.month" @change="updateCriteriaDate">
                     <option value="1">January</option>
                     <option value="2">February</option>
                     <option value="3">March</option>
                     <option value="4">April</option>
                     <option value="5">May</option>
                     <option value="6">June</option>
                     <option value="7">July</option>
                     <option value="8">August</option>
                     <option value="9">September</option>
                     <option value="10">October</option>
                     <option value="11">November</option>
                     <option value="12">December</option>
                  </select>&nbsp;
                  <select class="day" name="day" v-model="criteria.day" @change="updateCriteriaDate">
                     <option value="1">&nbsp;1</option>
                     <option value="2">&nbsp;2</option>
                     <option value="3">&nbsp;3</option>
                     <option value="4">&nbsp;4</option>
                     <option value="5">&nbsp;5</option>
                     <option value="6">&nbsp;6</option>
                     <option value="7">&nbsp;7</option>
                     <option value="8">&nbsp;8</option>
                     <option value="9">&nbsp;9</option>
                     <option value="10">10</option>
                     <option value="11">11</option>
                     <option value="12">12</option>
                     <option value="13">13</option>
                     <option value="14">14</option>
                     <option value="15">15</option>
                     <option value="16">16</option>
                     <option value="17">17</option>
                     <option value="18">18</option>
                     <option value="19">19</option>
                     <option value="20">20</option>
                     <option value="21">21</option>
                     <option value="22">22</option>
                     <option value="23">23</option>
                     <option value="24">24</option>
                     <option value="25">25</option>
                     <option value="26">26</option>
                     <option value="27">27</option>
                     <option value="28">28</option>
                     <option value="29">29</option>
                     <option value="30">30</option>
                     <option value="31">31</option>
                  </select>&nbsp;&nbsp;&nbsp;
                  <input
                     type="number"
                     name="year"
                     min="-12999"
                     max="16799"
                     step="1"
                     placeholder="YEAR"
                     v-model="criteria.year"
                     @change="updateCriteriaDate"
                     style="width:125px"
                     />
               </td>
            </tr>
            <tr title="Birth Time">
               <td width="100%" align="left">
                  <select class="hour" name="hour" v-model="criteria.hour" @change="updateCriteriaDate">
                     <option value="0">&nbsp;0 - 12 AM</option>
                     <option value="1">&nbsp;1 - 01 AM</option>
                     <option value="2">&nbsp;2 - 02 AM</option>
                     <option value="3">&nbsp;3 - 03 AM</option>
                     <option value="4">&nbsp;4 - 04 AM</option>
                     <option value="5">&nbsp;5 - 05 AM</option>
                     <option value="6">&nbsp;6 - 06 AM</option>
                     <option value="7">&nbsp;7 - 07 AM</option>
                     <option value="8">&nbsp;8 - 08 AM</option>
                     <option value="9">&nbsp;9 - 09 AM</option>
                     <option value="10">10 - 10 AM</option>
                     <option value="11">11 - 11 AM</option>
                     <option value="12">12 - 12 PM</option>
                     <option value="13">13 - 01 PM</option>
                     <option value="14">14 - 02 PM</option>
                     <option value="15">15 - 03 PM</option>
                     <option value="16">16 - 04 PM</option>
                     <option value="17">17 - 05 PM</option>
                     <option value="18">18 - 06 PM</option>
                     <option value="19">19 - 07 PM</option>
                     <option value="20">20 - 08 PM</option>
                     <option value="21">21 - 09 PM</option>
                     <option value="22">22 - 10 PM</option>
                     <option value="23">23 - 11 PM</option>
                  </select>&nbsp;
                  <select class="minute" name="minute" v-model="criteria.minute" @change="updateCriteriaDate">
                     <option value="0">00</option>
                     <option value="1">01</option>
                     <option value="2">02</option>
                     <option value="3">03</option>
                     <option value="4">04</option>
                     <option value="5">05</option>
                     <option value="6">06</option>
                     <option value="7">07</option>
                     <option value="8">08</option>
                     <option value="9">09</option>
                     <option value="10">10</option>
                     <option value="11">11</option>
                     <option value="12">12</option>
                     <option value="13">13</option>
                     <option value="14">14</option>
                     <option value="15">15</option>
                     <option value="16">16</option>
                     <option value="17">17</option>
                     <option value="18">18</option>
                     <option value="19">19</option>
                     <option value="20">20</option>
                     <option value="21">21</option>
                     <option value="22">22</option>
                     <option value="23">23</option>
                     <option value="24">24</option>
                     <option value="25">25</option>
                     <option value="26">26</option>
                     <option value="27">27</option>
                     <option value="28">28</option>
                     <option value="29">29</option>
                     <option value="30">30</option>
                     <option value="31">31</option>
                     <option value="32">32</option>
                     <option value="33">33</option>
                     <option value="34">34</option>
                     <option value="35">35</option>
                     <option value="36">36</option>
                     <option value="37">37</option>
                     <option value="38">38</option>
                     <option value="39">39</option>
                     <option value="40">40</option>
                     <option value="41">41</option>
                     <option value="42">42</option>
                     <option value="43">43</option>
                     <option value="44">44</option>
                     <option value="45">45</option>
                     <option value="46">46</option>
                     <option value="47">47</option>
                     <option value="48">48</option>
                     <option value="49">49</option>
                     <option value="50">50</option>
                     <option value="51">51</option>
                     <option value="52">52</option>
                     <option value="53">53</option>
                     <option value="54">54</option>
                     <option value="55">55</option>
                     <option value="56">56</option>
                     <option value="57">57</option>
                     <option value="58">58</option>
                     <option value="59">59</option>
                  </select>&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;<b>{{getUTCString()}}</b>
               </td>
            </tr>
            <tr title="Chart Title">
               <td width="100%" align="left">
                  <input
                     type="text"
                     minlength="2"
                     maxlength="45"
                     placeholder="Chart Title / Name / Initials"
                     v-model="criteria.client"
                     @input="updatePdfUrl()"
                     style="height: 25px; width: 310px; vertical-align: top;"
                     />
               </td>
            </tr>
         </table>
      </div>
      <div class="button-section">
         <table width="100%" border="0">
            <tr>
               <td width="100%" align="center">
                  <button @click="updatePdfUrl();viewChart()">&nbsp;Show Chart&nbsp;</button>&nbsp;&nbsp;
               </td>
            </tr>
         </table>
      </div>
   </div>
</div>
</template>


<script>
import HTTP from "@/http-axios";
import ApiBaseUrl from "@/http-api-base-url";
import { queryString, criteriaToken, getUTCString } from "@/util.js";
import WorldCitySearch from "@/components/widget-city-search/WorldCitySearch.vue";

export default {
    components: { WorldCitySearch },
    props: {
		widgetCriteria : {
			type: Object
		},
    },
    data() {
        return {
            criteria: {
                deviceType: "WGT",
                client: '',

                year: 0,
                month: 0,
                day: 0,
                hour: 0,
                minute: 0,
                second: 0,

                location: '',
                latitude: 0.0,
                longitude: 0.0,
                timeOffset: 0.0,
                timeOffsetMinutes: 0,
                timezone: '',

                username: '',
                email: '',
                ipAddress: '',

                chartStyle: 0,
                ayanamsa: 1,
                bhavaChartType: 79,
                dasaBuktiDaysInYear: 365.256,
                aspectDegreeLimit: 15,
                conjunctionDegreeLimit: 20,
                rahuNode: 10,
                topocentricPlanets: 0,
                nutationRequired: 1,
                hinduSunrise: 1,
            },
            pdfUrl: "",
        };
    },

    beforeCreate() {
        if ( localStorage.getItem("ncwtz") ) {
            // A location with timezone already exists
        } else {
            // Clear the existing location data
            // We do not have timezone for existing location
            // Lets start afresh with default location
            // Let the User pick their choice of location
            localStorage.removeItem("ncwloc");
            localStorage.removeItem("ncwlat");
            localStorage.removeItem("ncwlong");
            localStorage.removeItem("ncwtimeOffsetMinutes");
            localStorage.removeItem("ncwtimeOffset");
            localStorage.removeItem("ncwtz");
        }
    },

    created() {

        this.criteria.deviceType = this.widgetCriteria.deviceType
        this.criteria.client = localStorage.getItem("ncwclient") ? localStorage.getItem("ncwclient") : this.widgetCriteria.client,

        this.criteria.year = localStorage.getItem("ncwyear") ? Number.parseInt(localStorage.getItem("ncwyear")) : this.widgetCriteria.year,
        this.criteria.month = localStorage.getItem("ncwmonth") ? Number.parseInt(localStorage.getItem("ncwmonth")) : this.widgetCriteria.month,
        this.criteria.day = localStorage.getItem("ncwday") ? Number.parseInt(localStorage.getItem("ncwday")) : this.widgetCriteria.day,
        this.criteria.hour = localStorage.getItem("ncwhour") ? Number.parseInt(localStorage.getItem("ncwhour")) : this.widgetCriteria.hour,
        this.criteria.minute = localStorage.getItem("ncwminute") ? Number.parseInt(localStorage.getItem("ncwminute")) : this.widgetCriteria.minute,
        this.criteria.second = localStorage.getItem("ncwsecond") ? Number.parseInt(localStorage.getItem("ncwsecond")) : this.widgetCriteria.second,

        this.criteria.location = localStorage.getItem("ncwloc") ? localStorage.getItem("ncwloc") : this.widgetCriteria.location,
        this.criteria.latitude = localStorage.getItem("ncwlat") ? new Number(localStorage.getItem("ncwlat")) : this.widgetCriteria.latitude,
        this.criteria.longitude = localStorage.getItem("ncwlong") ? new Number(localStorage.getItem("ncwlong")) : this.widgetCriteria.longitude,
        this.criteria.timeOffset = localStorage.getItem("ncwtimeOffset") ? new Number(localStorage.getItem("ncwtimeOffset")) : this.widgetCriteria.timeOffset,
        this.criteria.timeOffsetMinutes = localStorage.getItem("ncwtimeOffsetMinutes") ? Number.parseInt(localStorage.getItem("ncwtimeOffsetMinutes")) : this.widgetCriteria.timeOffsetMinutes,
        this.criteria.timezone =  localStorage.getItem("ncwtz") ? localStorage.getItem("ncwtz") : this.widgetCriteria.timezone,        

        this.criteria.username = this.widgetCriteria.username,
        this.criteria.email = this.widgetCriteria.email
        this.criteria.ipAddress = this.widgetCriteria.ipAddress

        this.criteria.chartStyle = this.widgetCriteria.chartStyle,
        this.criteria.ayanamsa = this.widgetCriteria.ayanamsa,
        this.criteria.bhavaChartType = this.widgetCriteria.bhavaChartType
        this.criteria.dasaBuktiDaysInYear = this.widgetCriteria.dasaBuktiDaysInYear,
        this.criteria.aspectDegreeLimit = this.widgetCriteria.aspectDegreeLimit
        this.criteria.conjunctionDegreeLimit = this.widgetCriteria.conjunctionDegreeLimit
        this.criteria.rahuNode = this.widgetCriteria.rahuNode,
        this.criteria.topocentricPlanets = this.widgetCriteria.topocentricPlanets
        this.criteria.nutationRequired = this.widgetCriteria.nutationRequired,
        this.criteria.hinduSunrise = this.widgetCriteria.hinduSunrise

        this.updatePdfUrl();
    },
    mounted() {},

    updated() {},

    methods: {
        updatePdfUrl: function () {
            this.pdfUrl = ApiBaseUrl() + "/api/pdf/natalchart_basic?" + this.queryString(this.criteria) + "&ct=" + this.getCriteriaToken(this.criteria);

            // set new client info
            localStorage.setItem("ncwclient", this.criteria.client);

            // set new date & time in local storage
            localStorage.setItem("ncwyear", this.criteria.year);
            localStorage.setItem("ncwmonth", this.criteria.month);
            localStorage.setItem("ncwday", this.criteria.day);
            localStorage.setItem("ncwhour", this.criteria.hour);
            localStorage.setItem("ncwminute", this.criteria.minute);

            // keep new location info in local storage
            localStorage.setItem("ncwloc", this.criteria.location);
            localStorage.setItem("ncwlat", this.criteria.latitude);
            localStorage.setItem("ncwlong", this.criteria.longitude);
            localStorage.setItem("ncwtimeOffset", this.criteria.timeOffset);
            localStorage.setItem("ncwtimeOffsetMinutes", this.criteria.timeOffsetMinutes);
            localStorage.setItem("ncwtz", this.criteria.timezone);            
        },
        queryString: function (obj) {
            return queryString(obj);
        },
        getCriteriaToken: function() {
            return criteriaToken(this.criteria);
        },
        getUTCString: function () {
            return getUTCString(this.criteria.timeOffsetMinutes);
        },
        setWorldCity: function (worldCity) {
            this.criteria.location = worldCity.city;
            this.criteria.latitude = worldCity.lat;
            this.criteria.longitude = worldCity.lng;
            this.criteria.timeOffset = worldCity.timeOffset;
            this.criteria.timeOffsetMinutes = worldCity.timeOffsetMinutes;
            this.criteria.timezone = worldCity.timezone;

            // Update PDF url for new location
            this.updatePdfUrl();

            // debug
            // console.log('ncwc:city: ' + this.criteria.location);
            // console.log('ncwc:lat: ' + this.criteria.latitude);
            // console.log('ncwc:lng: ' + this.criteria.longitude);
            // console.log('ncwc:timeOffset: ' + this.criteria.timeOffset);
            // console.log('ncwc:timeOffsetMinutes: ' + this.criteria.timeOffsetMinutes);
            // console.log('ncwc:timezone: ' + this.criteria.timezone);
        },
        updateCriteriaDate: function () {
            // Year value validation
            if (this.criteria.year) {
                var selectedYear = Number.parseInt(this.criteria.year);
                if (selectedYear < -12999 || selectedYear > 16799) {
                    window.alert("Unsupported year : " + selectedYear + ". Valid year range : 13000 BC - 16798 AD");
                    this.criteria.year = localStorage.getItem("ncwyear") ? Number.parseInt(localStorage.getItem("ncwyear")) : new Date().getFullYear();
                }
            } else {
                // Empty input year so set current year
                this.criteria.year = new Date().getFullYear();
            }

            // Check for timeOffset Change
            this.checkTimeOffsetChangeForDate();

            // Update PDF url for new date / time
            this.updatePdfUrl();
        },
        checkTimeOffsetChangeForDate: function() {
            HTTP().post(`/api/data/city/timeOffset?city=` + this.criteria.location + '&timezone=' + this.criteria.timezone 
                        + '&year=' + this.criteria.year + '&month=' + this.criteria.month + '&day=' + this.criteria.day 
                        + '&hour=' + this.criteria.hour + '&minute=' + this.criteria.minute + '&second=' + this.criteria.second)
            .then(response => {
                var cityTimeOffset = response.data
                console.log('cityTimeOffset: ' + JSON.stringify(cityTimeOffset))
                if ( cityTimeOffset ) {
                    this.criteria.timeOffset = cityTimeOffset
                    this.criteria.timeOffsetMinutes = cityTimeOffset * 60.0
                    localStorage.setItem("ncwtimeOffset", this.criteria.timeOffset);
                    localStorage.setItem("ncwtimeOffsetMinutes", this.criteria.timeOffsetMinutes);
                }
            })
            .catch(e => {
                console.log(e)
            })
        },
        gotoVAC() {
            window.open("https://www.vedicastrochart.com/natal-chart?" + this.queryString(this.criteria), "_blank");
        },
        viewChart() {
            window.open(this.pdfUrl, "_blank");
        },
        
    },
    beforeUnmount() {
        // Capture chart settings before leaving the component
        localStorage.setItem("ncwclient", this.criteria.client);
        localStorage.setItem("ncwyear", this.criteria.year);
        localStorage.setItem("ncwmonth", this.criteria.month);
        localStorage.setItem("ncwday", this.criteria.day);
        localStorage.setItem("ncwhour", this.criteria.hour);
        localStorage.setItem("ncwminute", this.criteria.minute);
        localStorage.setItem("ncwloc", this.criteria.location);
        localStorage.setItem("ncwlat", this.criteria.latitude);
        localStorage.setItem("ncwlong", this.criteria.longitude);
        localStorage.setItem("ncwtimeOffsetMinutes", this.criteria.timeOffsetMinutes);
        localStorage.setItem("ncwtz", this.criteria.timezone);
    },
};
</script>

<style scoped>
* {
    font-family: sans-serif;
}

.pointer {
    cursor: pointer;
}

.screen-wrapper {
    border: 1px solid lightgray;
    background: #EDF6F9;
    border-radius: 10px;
    clear: both;
    align-content: center;
    width: 325px;
    height: 320px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.chart-criteria-wrapper {
    border: 0px solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-section {
    width: 100%;
    background-color: #344E41;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 2px 2px 2px #A3B18A;
}

.input-section {
    width: 100%;
    margin-top: 10px;
}

.button-section {
    width: 100%;
    margin-top: 5px;
}

input[type="text"] {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 10px;
    padding: 15px 15px;
    box-sizing: border-box;
    border: 2px solid #087f8c;
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: none;
}

input[type="text"]:focus {
    border: 2px solid #acd8aa;
}

input[type="number"] {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 10px;
    padding: 15px 15px;
    box-sizing: border-box;
    border: 2px solid #087f8c;
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: none;
}

input[type="number"]:focus {
    border: 2px solid #acd8aa;
}

button {
    background-color: #087f8c;
    color: #ffffff;
    font-weight: bold;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    text-align: center;
}

select {
    margin: 1px;
    padding: 5px;
    height: 35px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    font: normal 14px;
    color: black;
    border: 2px solid #087f8c;
    border-color: 1px solid #087f8c;
    outline: none;
    border-radius: 10px;
}

select:focus {
    border: 2px solid #acd8aa;
}

</style>
