<template>
	<div class="screen-wrapper">
		<div class="header-area" v-if="horoscope.criteria">
			<div class="title-display" v-if="horoscope.criteria.client">
				{{horoscope.criteria.client}}
			</div>
			<div class="date-display">
				{{horoscope.criteria.dateInfo}}, {{horoscope.criteria.weekday}}
			</div>
			<div class="location-display">
				{{horoscope.criteria.location}},<br/>
				Lat={{horoscope.criteria.latitude}}, Long={{horoscope.criteria.longitude}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.screen-wrapper {
  border: 0px solid;
  width: 100%;
}

.header-area {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: left; 
	text-align: left;
	width:50%;
	padding-top: 10px;
}

.title-display {
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
	font-weight: bold; 
	text-decoration: underline;
}

.date-display {
	font-family: 'Times New Roman', Times, serif;
	font-size: 16px;
}

.location-display {
	font-family: 'Times New Roman', Times, serif;
	font-size: 16px;
}

</style>
