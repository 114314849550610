<template>
    <div class="home-wrapper">
        <div class="home-content">
            <div class="vedic-chart-intro">
                <u>
                    <h3>Vedic Astrology Chart</h3>
                </u>
                <p>
                    Create Your Free Vedic Astrology Natal or Birth Chart. Vedic astrology chart is a snapshot of planetary positions in the Zodiac at the time of birth where Earth is the place of the observer (geocentric). Sidereal planetary positions are used to create a Vedic astrology chart and it includes:
                <ul>
                    <li>Planetary Positions</li>
                    <li>Planetary Strength</li>
                    <li>Planetary Aspects</li>
                    <li>Planetary Conjunctions</li>
                    <li>House Cusps and Midpoints</li>
                    <li>Divisional Chart Images</li>
                    <li>Vimsottari Dasa Periods</li>
                    <li>Natal Panchanga (Vedic Calendar)</li>
                    <li>and more as a <b>PDF</b> document</li>
                </ul>
                </p>
            </div>
            <div class="sample-chart-image">
              <a @click="$router.push('natal-chart')">
                <img width="285" height="403" src="../assets/sample-vedic-astrology-chart.png" alt="Sample Vedic Astrology Chart" />
              </a>
            </div>
        </div>
        <div class="natal-chart-button-area">
            <a class="natal-chart-link" @click="$router.push('natal-chart')">&nbsp;Create Natal Chart&nbsp;</a>
        </div>
    </div>
</template>

<style scoped>

body {
  font-family: Arial, Helvetica, sans-serif;
}

h3 {
  margin: 25px 0 0;
}

.natal-chart-link {
  border-radius: 5px;
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  padding: 12px;
  text-decoration: none;
}

li {
  text-align: left;
  padding: 5px;
}

p {
  color: #344E41;
  text-indent: 10px;
  text-align: left;
  padding: 10px;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
  border-radius: 10px;
  justify-content: center;
  padding-top: 50px;
}

.home-content {
  border: 1px solid;
  border-color: #a0a0a0;
  align-content: center;
  background: #EDF6F9;
  border-radius: 10px;
  margin-top: 10px;

  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.natal-chart-button-area {
  width: 50%;
  border: 0px solid;
  padding: 30px;
  cursor: pointer;
}

.sample-chart-image {
  border: 0px solid;
  width: 35%;
  padding: 5px;
  cursor: pointer;
}
.vedic-chart-intro {
  width: 65%;
  padding: 5px;
}

@media screen and (max-width: 1605px) {

  p {
    color: #344E41;
    text-indent: 10px;
    text-align: left;
    padding: 10px;
  }

  h3 {
    margin: 25px 0 0;
  }

  li {
    text-align: left;
    padding: 3px;
    list-style-position: outside;
  }  

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .home-content {
    border: 1px solid;
    border-color: #a0a0a0;
    align-content: center;
    background: #EDF6F9;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .natal-chart-button-area {
    width: 90%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .sample-chart-image {
    border: 0px solid;
    width: 90%;
    padding: 5px;
    cursor: pointer;
  }

  .vedic-chart-intro {
    width: 90%;
    padding: 5px;
  }

}

@media screen and (max-width: 1205px) {

  p {
    color: #344E41;
    text-indent: 10px;
    text-align: left;
    padding: 10px;
  }

  h3 {
    margin: 25px 0 0;
  }

  li {
    text-align: left;
    padding: 3px;
    list-style-position: outside;
  }  

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .home-content {
    border: 1px solid;
    border-color: #a0a0a0;
    align-content: center;
    background: #EDF6F9;
    border-radius: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .natal-chart-button-area {
    width: 100%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .sample-chart-image {
    border: 0px solid;
    width: 100%;
    padding: 5px;
    cursor: pointer;
  }

  .vedic-chart-intro {
    width: 100%;
    padding: 5px;
  }

}
</style>