<template>
	<div class="vertical-menu">
		<a @click="activate('home')" :class="{active: activeLink == 'home'}">Home</a>
		<a @click="activate('natal-planets')" :class="{active: activeLink == 'natal-planets'}">Planets</a>
		<a @click="activate('planetary-strength')" :class="{active: activeLink == 'planetary-strength'}">Strength</a>
		<a @click="activate('aspects')" :class="{active: activeLink == 'aspects'}">Aspects</a>
		<a @click="activate('conjunction')" :class="{active: activeLink == 'conjunction'}">Conjunction</a>
		<a @click="activate('bhava')" :class="{active: activeLink == 'bhava'}">Houses</a>
		<a @click="activate('varga')" :class="{active: activeLink == 'varga'}">Divisional Chart</a>
		<!--<a @click="activate('vimsottri-dasa')" :class="{active: activeLink == 'vimsottri-dasa'}">Old Vimsottari Dasa</a>-->
		<a @click="activate('filtered-vimsottri-dasa')" :class="{active: activeLink == 'filtered-vimsottri-dasa'}">Vimsottari Dasa</a>
		<a @click="activate('panchanga')" :class="{active: activeLink == 'panchanga'}">Panchanga</a>
		<a @click="activate('view-summary-pdf')" :class="{active: activeLink == 'view-summary-pdf'}">Basic Chart</a>
		<a  v-if="criteria.username == 'vacadmin'" @click="activate('view-pdf')" :class="{active: activeLink == 'view-pdf'}">Complete Chart</a>
		<a @click="activate('buy-complete-pdf')" :class="{active: activeLink == 'buy-complete-pdf'}">Complete Chart</a>
		<a> <!-- Donation -->
			<form action="https://www.paypal.com/donate" method="post" target="_top">
				<input type="hidden" name="hosted_button_id" value="DAJNLMZNENE2Q" />
				<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
				<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
			</form>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		criteria : {
			type: Object
		}
	},
	data() {
		return {
			activeLink: ''
		}
	},
	methods: {
		activate(menuItem) {
			this.activeLink = menuItem;
			this.$emit('activeMenuItem', menuItem);
		}
	},
	created() {
		this.activeLink = 'natal-planets';
	}
}
</script>

<style scoped>
	.vertical-menu {
		width: 100%; /* Set a width if you like */
		cursor: pointer;
		position: fixed;
	}

	.vertical-menu a {
		background-color: #344E41; /* Grey background color */
		color: #FFFFFF; /* Black text color */
		display: block; /* Make the links appear below each other */
		padding: 10px; /* Add some padding */
		text-decoration: none; /* Remove underline from links */
		text-align: left;
	}

	.vertical-menu a:hover {
		background-color: #344E41; /* Dark grey background on mouse-over */
	}

	.vertical-menu a.active {
		background-color: #FF9F1C; /* Add a green color to the "active/current" link */
		color: white;
	}

	.fontAwesomeIcon {
		padding-right: 5px;
		pointer-events: none;
	}

	@media print {
		.vertical-menu {
			display: none;
		}
	}

</style>
