<template>
    <div class="screen-wrapper">
        <div class="vimsottri-dasa-legend">
			<table class="dasa-table-legend" width="100%" border="0"> 
				<thead>
					<tr>
						<th colspan="2">Vimsottari Dasa</th>
					</tr>
				</thead>
                <tbody>
				<tr>
					<td align="center" width="25%"><b>D</b></td>
					<td align="center"><b>Dasa / Maha Dasa</b></td>	
				</tr>
				<tr>
					<td align="center" width="25%"><b>B</b></td>
					<td align="center"><b>Bhukti / Antar Dasa</b></td>	
				</tr>
				<tr>
					<td align="center" width="25%"><b>A</b></td>
					<td align="center"><b>Antara / Pratyantar Dasa</b></td>	
				</tr>
                </tbody>
            </table>
        </div>

        <div class="vimsottri-dasa">
            <table class="dasa-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:10%">
                <col style="width:35%">
                <col style="width:35%">
                <thead>
                    <tr>
                        <th align="left">D</th>
                        <th align="left">B</th>
                        <th align="left">A</th>
                        <th align="center">Start Date</th>
                        <th align="center">End Date</th>
                    </tr>
                </thead>
                <tbody v-for="dasa in dasaList" :key="dasa.order">
                    <tr @click="toggleDasa(dasa.lord)" v-if="dasa.current && selectedDasa==dasa.lord" style="background:#FFFF66">
                        <td align="left"><b>{{dasa.lordAbbreviation}}</b></td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="dasa.current" style="background:#FFFF66">
                        <td align="left">{{dasa.lordAbbreviation}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="dasa.beforeBirth" style="background:#DDD">
                        <td align="left">{{dasa.lordAbbreviation}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else-if="selectedDasa==dasa.lord">
                        <td align="left"><b>{{dasa.lordAbbreviation}}</b></td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                    </tr>
                    <tr @click="toggleDasa(dasa.lord)" v-else>
                        <td align="left">{{dasa.lordAbbreviation}}</td>
                        <td align="left">&nbsp;</td>
                        <td align="left">&nbsp;</td>
                        <td align="center">{{dasa.formattedStartDate}}</td>
                        <td align="center">{{dasa.formattedEndDate}}</td>
                    </tr>
                    <tr v-if="selectedDasa==dasa.lord">
                        <td colspan="8">
                            <table class="bukti-table" cellspacing="0" cellpadding="0" border="0">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:35%">
                                <col style="width:35%">
                                <tbody v-for="bukti in buktiList" :key="bukti.order">
                                    <tr @click="toggleBukti(bukti.lord)" v-if="bukti.current && selectedBukti==bukti.lord" style="background:#FFFF66">
                                        <td align="left"><b>{{dasa.lordAbbreviation}}</b></td>
                                        <td align="left"><b>{{bukti.lordAbbreviation}}</b></td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="bukti.current" style="background:#FFFF66">
                                        <td align="left">{{dasa.lordAbbreviation}}</td>
                                        <td align="left">{{bukti.lordAbbreviation}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="bukti.beforeBirth" style="background:#DDD">
                                        <td align="left">{{dasa.lordAbbreviation}}</td>
                                        <td align="left">{{bukti.lordAbbreviation}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else-if="selectedBukti==bukti.lord">
                                        <td align="left"><b>{{dasa.lordAbbreviation}}</b></td>
                                        <td align="left"><b>{{bukti.lordAbbreviation}}</b></td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                    </tr>
                                    <tr @click="toggleBukti(bukti.lord)" v-else>
                                        <td align="left">{{dasa.lordAbbreviation}}</td>
                                        <td align="left">{{bukti.lordAbbreviation}}</td>
                                        <td align="left">&nbsp;</td>
                                        <td align="center">{{bukti.formattedStartDate}}</td>
                                        <td align="center">{{bukti.formattedEndDate}}</td>
                                    </tr>
                                    <tr v-if="selectedBukti==bukti.lord">
                                        <td colspan="8">
                                            <table class="antaram-table" cellspacing="0" cellpadding="0" border="0">
                                                <col style="width:10%">
                                                <col style="width:10%">
                                                <col style="width:10%">
                                                <col style="width:35%">
                                                <col style="width:35%">
                                                <tbody v-for="antaram in antaramList" :key="antaram.order">
                                                    <tr v-if="antaram.current" style="background:#FFFF66">
                                                        <td align="left"><b>{{dasa.lordAbbreviation}}</b></td>
                                                        <td align="left"><b>{{bukti.lordAbbreviation}}</b></td>
                                                        <td align="left"><b>{{antaram.lordAbbreviation}}</b></td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                    </tr>
                                                    <tr v-else-if="antaram.beforeBirth" style="background:#DDD">
                                                        <td align="left">{{dasa.lordAbbreviation}}</td>
                                                        <td align="left">{{bukti.lordAbbreviation}}</td>
                                                        <td align="left">{{antaram.lordAbbreviation}}</td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <td align="left">{{dasa.lordAbbreviation}}</td>
                                                        <td align="left">{{bukti.lordAbbreviation}}</td>
                                                        <td align="left">{{antaram.lordAbbreviation}}</td>
                                                        <td align="center">{{antaram.formattedStartDate}}</td>
                                                        <td align="center">{{antaram.formattedEndDate}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/util.js'
import HTTP from '@/http-axios';

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
            dasaList: {},
            buktiList: {},
            antaramList: {},
            selectedDasa: '',
            selectedBukti: '',
            pageLoading: false
		}
	},
	methods: {
        toggleDasa( dasaClicked ) {
            if ( this.selectedDasa == dasaClicked ) {
                this.selectedDasa = ''
                this.selectedBukti = ''
            } else {
                this.selectedDasa = dasaClicked
                this.selectedBukti = ''
                this.buktiList = {};
                this.getBhukti()
            }
        },
        toggleBukti( buktiClicked ) {
            if ( this.selectedBukti == buktiClicked ) {
                this.selectedBukti = ''
            } else {
                this.selectedBukti = buktiClicked
                this.antaramList = {};
                this.getAntaram()
            }
        },
		queryString: function( obj ) {
			return queryString(obj);
		},
		getDasa: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.dasaList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
		getBhukti: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?filterDasa=' + 
                            this.selectedDasa + 
                            '&' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.buktiList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
		getAntaram: function() {
			this.pageLoading = true;
			HTTP().post('/api/data/vimsottari/filtered?filterDasa=' + 
                            this.selectedDasa + 
                            '&filterBhukti=' + 
                            this.selectedBukti + 
                            '&' + this.queryString(this.horoscope.criteria))
			.then(response => {
				this.antaramList = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
	},
	created() {
		this.getDasa()
	},
}
</script>

<style scoped>

.screen-wrapper {
  width: 100%;
}

.vimsottri-dasa {
    border: 0px solid;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.vimsottri-dasa-legend {
    border: 0px solid;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dasa-table-legend {
    width: 95%;
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
    background-color: #ffffff;
}

.dasa-table-legend thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
    font-size: 1.3em;    
}

.dasa-table-legend th {
    height: 30px;
    padding: 5px;
}

.dasa-table-legend td {
    height: 30px;
    color: #344E41;
}

.dasa-table-legend tbody tr {
    border-bottom: 1px solid #dddddd;
}

.dasa-table-legend tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}


.dasa-table {
    width: 95%;
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
    background-color: #ffffff;
}

.dasa-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
}

.dasa-table th {
    height: 30px;
    padding: 5px;
}

.dasa-table td {
    height: 30px;
    color: #344E41;
    cursor: pointer;
}

.dasa-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.dasa-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.bukti-table {
    width: 100%;
    border-collapse: collapse;
    font-family: sans-serif;
    overflow: hidden;
}

.bukti-table td {
    color: #344E41;
    cursor: pointer;
}

.bukti-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.bukti-table tbody tr:nth-of-type(odd) {
    background-color: #EDF6F9;
}

.antaram-table {
    width: 100%;
    border-collapse: collapse;
    font-family: sans-serif;
    overflow: hidden;
}

.antaram-table td {
    color: #344E41;
    cursor: default;
}

.antaram-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.antaram-table tbody tr:nth-of-type(odd) {
    background-color: #e6ffe6;
}

.current {
    background-color: #ffff66;
}

</style>
