<template>
	<div class="screen-wrapper">
		<div class="chart-area">
			<div class="chart-image">
				<img alt="Chart-Image" :src="imageDataURI">
			</div>
			<div class="chart-image-title">{{chartImageTitle}}</div>
			<br/>
			<div class="chart-choice">
				<select v-if="showChartType" class="chart-choice-list" name="chartType" v-model="chartType" style="float:left;margin:5px" @change="getImageDataURI()">
					<option value="D1">D1 - RASI</option>
					<option value="D2">D2 - HORA</option>
					<option value="D3">D3 - DREKKANA</option>
					<option value="D4">D4 - CHATURTHAMSA</option>
					<option value="D5">D5 - PANCHAMSA</option>
					<option value="D6">D6 - SHASHTAMSA</option>
					<option value="D7">D7 - SAPTAMSHA</option>
					<option value="D8">D8 - ASHTAMSA</option>
					<option value="D9">D9 - NAVAMSA</option>
					<option value="D10">D10 - DASAMSA</option>
					<option value="D11">D11 - RUDRAMSA</option>
					<option value="D12">D12 - DWADASAMSA</option>
					<option value="D16">D16 - SHODASAMSA</option>
					<option value="D20">D20 - VIMSAMSA</option>
					<option value="D24">D24 - CHATURVIMSAMSA</option>
					<option value="D27">D27 - NAKSHATRAMSA</option>
					<option value="D30">D30 - TRIMSAMSA</option>
					<option value="D40">D40 - KHAVEDAMSA</option>
					<option value="D45">D45 - AKSHAVEDAMSA</option>
					<option value="D60">D60 - SHASTIAMSA</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import HTTP from '@/http-axios';
import { queryString } from '@/util.js'

export default {
	props: {
		criteria : {
			type: Object
		},
		chartImageId: {
			type: String
		},
		chartImageTitle: {
			type: String
		},
		initialChartType: {
			type: String
		},
		showChartTypeConfig: {
			type: Boolean
		}
	},
	data() {
		return {
			id: '',
			chartType: 'D1',
			showChartType: false,
			imageDataURI: ""
		}
	},
	created() {
		if ( this.chartImageId ) {
			this.id = this.chartImageId
		}

		if ( this.initialChartType ) {
			this.chartType = this.initialChartType
		}

		this.showChartType = this.showChartTypeConfig;

		if ( localStorage.getItem(this.id + '-hctype') ) {
			this.chartType = localStorage.getItem(this.id + '-hctype')
		}

		this.getImageDataURI()
	},
	methods: {
		getImageDataURI: function() {
			HTTP().post(this.getChartUrl())
			.then(response => {
				this.imageDataURI = response.data
			})
			.catch(e => {
				console.log(e)
			})
		},
		getChartUrl() {
			switch( this.chartType ) {
				case "D1": return '/api/image/varga?vargaChartType=1&' + queryString(this.criteria);
				case "D2": return '/api/image/varga?vargaChartType=2&' + queryString(this.criteria);
				case "D3": return '/api/image/varga?vargaChartType=3&' + queryString(this.criteria);
				case "D4": return '/api/image/varga?vargaChartType=4&' + queryString(this.criteria);
				case "D5": return '/api/image/varga?vargaChartType=5&' + queryString(this.criteria);
				case "D6": return '/api/image/varga?vargaChartType=6&' + queryString(this.criteria);
				case "D7": return '/api/image/varga?vargaChartType=7&' + queryString(this.criteria);
				case "D8": return '/api/image/varga?vargaChartType=8&' + queryString(this.criteria);
				case "D9": return '/api/image/varga?vargaChartType=9&' + queryString(this.criteria);
				case "D10": return '/api/image/varga?vargaChartType=10&' + queryString(this.criteria);
				case "D11": return '/api/image/varga?vargaChartType=11&' + queryString(this.criteria);
				case "D12": return '/api/image/varga?vargaChartType=12&' + queryString(this.criteria);
				case "D16": return '/api/image/varga?vargaChartType=16&' + queryString(this.criteria);
				case "D20": return '/api/image/varga?vargaChartType=20&' + queryString(this.criteria);
				case "D24": return '/api/image/varga?vargaChartType=24&' + queryString(this.criteria);
				case "D27": return '/api/image/varga?vargaChartType=27&' + queryString(this.criteria);
				case "D30": return '/api/image/varga?vargaChartType=30&' + queryString(this.criteria);
				case "D40": return '/api/image/varga?vargaChartType=40&' + queryString(this.criteria);
				case "D45": return '/api/image/varga?vargaChartType=45&' + queryString(this.criteria);
				case "D60": return '/api/image/varga?vargaChartType=60&' + queryString(this.criteria);
			}
		},
		queryString: function( obj ) {
			return queryString(obj);
		},
	},
	beforeUnmount() {	
		localStorage.setItem(this.id + '-hctype', this.chartType)
	}
}
</script>

<style scoped>

.screen-wrapper {
  border: 0px solid;
  width: 100%;
}

.chart-area {
	border: 0px solid;
	display: block;
	float: left;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
	margin-top:10px
}

.chart-image-title {
    font-size: 0.9em;
    font-family: sans-serif;
    color: #344E41;
    text-align: left;
    font-weight: bold;
	margin: 5px auto;
}

</style>
