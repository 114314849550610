<template>
    <div class="page-title">
        <h2>Jamakkol Horary</h2>
    </div>
    <!-- START of screen wrapper -->
    <div class="loading-spinner-area" v-if="!transitInfo.chartImages">
        <img class="screen-centered-img" src="../assets/spinner.gif"/>
    </div>
    <div class="planetary-positions-wrapper" v-show="transitInfo.chartImages">
        <div class="time-change-row">
            <table cellspacing="15" border="0" align="center">
                <tr>
                    <td @click="updateTimeChange(-1)">
                        <button>
                        <!--<i class="fas fa-minus-square fa-lg"></i>-->
                        <font-awesome-icon :icon="['fas', 'square-minus']" size="2x" />
                        </button>
                    </td>
                    <td>
                        <select class="month" name="timeChange" v-model="timeChangeField">
                            <option value="minute">Minute</option>
                            <option value="hour">Hour</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                        </select>
                    </td>
                    <td @click="updateTimeChange(1)">
                        <button>
                        <!--<i class="fas fa-plus-square fa-lg"></i>-->
                        <font-awesome-icon :icon="['fas', 'square-plus']" size="2x" />
                        </button>
                    </td>
                    <td>
                        <button @click="setCurrentDateTime" 
                            style="height:25px;background-color:#087F8C;color:white">&nbsp;Now&nbsp;</button>                            
                    </td>
                    <td>
                        <a href="#panchanga" style="text-decoration:none;color:#087F8C" title="View panchanga below">
                            <!--<i class="far fa-calendar-alt fa-lg"></i>-->
                            <font-awesome-icon :icon="['fas', 'calendar-days']" size="lg" />
                        </a>
                    </td>
                    <td>
                        <a href="#settings" style="text-decoration:none;color:#087F8C" title="Change settings below">
                            <!--<i class="fas fa-cog fa-lg"></i>-->
                            <font-awesome-icon :icon="['fas', 'gear']" size="lg" />
                        </a>
                    </td>
                </tr>
            </table>
        </div>
        <div class="date-time-display">
            <h3 v-if="transitInfo.criteria">{{ transitInfo.criteria.dateInfo }}</h3>
        </div>
        <div class="rasi" v-if="transitInfo.chartImages">
            <img :src="transitInfo.chartImages.imageMap.JK" alt="JK" :width="jkImageWidth">
        </div>
        <div class="planets-details">
            <ShowPlanetsTable :horoscope="transitInfo" :planetsTitle="'Planetary Positions'" />
        </div>
        <div class="panchanga-details">
            <a name="panchanga">
                <MobilePlanetaryPositionsPanchanga :horoscope="transitInfo" />
            </a>
        </div>
        <div class="datetime-location">
            <div class="date-input">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left">
                            <h2><a name="settings">Settings</a></h2>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="far fa-calendar-alt fa-lg"></i>&nbsp;-->
                            <font-awesome-icon :icon="['fas', 'calendar-days']" size="lg" class="fontAwesomeIcon" />
                            <b>Date</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="month" v-model="criteria.month" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select class="day" name="day" v-model="criteria.day" @change="updateCriteriaDate" style="float:left;margin:5px;width:45px;">
                                <option value="1">&nbsp;1</option>
                                <option value="2">&nbsp;2</option>
                                <option value="3">&nbsp;3</option>
                                <option value="4">&nbsp;4</option>
                                <option value="5">&nbsp;5</option>
                                <option value="6">&nbsp;6</option>
                                <option value="7">&nbsp;7</option>
                                <option value="8">&nbsp;8</option>
                                <option value="9">&nbsp;9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                            <input 
								type="number" 
								name="year" 
								min="-12999" 
								max="16799" 
								step="1" 
								placeholder="YEAR" 
								v-model="criteria.year" 
								@change="updateCriteriaDate" 
								style="float:left;margin:5px;height:65%;"/>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="time-input">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="far fa-clock fa-lg"></i>&nbsp;-->
                            <font-awesome-icon :icon="['far', 'clock']" size="lg" class="fontAwesomeIcon" />
                            <b>Time</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="hour" name="hour" v-model="criteria.hour" @change="updateCriteriaDate" style="float:left;margin:5px">
                                <option value="0">&nbsp;0 - 12 AM</option>
                                <option value="1">&nbsp;1 - 01 AM</option>
                                <option value="2">&nbsp;2 - 02 AM</option>
                                <option value="3">&nbsp;3 - 03 AM</option>
                                <option value="4">&nbsp;4 - 04 AM</option>
                                <option value="5">&nbsp;5 - 05 AM</option>
                                <option value="6">&nbsp;6 - 06 AM</option>
                                <option value="7">&nbsp;7 - 07 AM</option>
                                <option value="8">&nbsp;8 - 08 AM</option>
                                <option value="9">&nbsp;9 - 09 AM</option>
                                <option value="10">10 - 10 AM</option>
                                <option value="11">11 - 11 AM</option>
                                <option value="12">12 - 12 PM</option>
                                <option value="13">13 - 01 PM</option>
                                <option value="14">14 - 02 PM</option>
                                <option value="15">15 - 03 PM</option>
                                <option value="16">16 - 04 PM</option>
                                <option value="17">17 - 05 PM</option>
                                <option value="18">18 - 06 PM</option>
                                <option value="19">19 - 07 PM</option>
                                <option value="20">20 - 08 PM</option>
                                <option value="21">21 - 09 PM</option>
                                <option value="22">22 - 10 PM</option>
                                <option value="23">23 - 11 PM</option>
                            </select>
                            <select class="minute" name="minute" v-model="criteria.minute" @change="updateCriteriaDate" style="float:left;margin:5px;width:45px;">
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                                <option value="51">51</option>
                                <option value="52">52</option>
                                <option value="53">53</option>
                                <option value="54">54</option>
                                <option value="55">55</option>
                                <option value="56">56</option>
                                <option value="57">57</option>
                                <option value="58">58</option>
                                <option value="59">59</option>
                            </select>
                            <select class="second" name="minute" v-model="criteria.second" @change="updateCriteriaDate" style="float:left;margin:5px;width:45px;">
                                <option value="0">00</option>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                                <option value="51">51</option>
                                <option value="52">52</option>
                                <option value="53">53</option>
                                <option value="54">54</option>
                                <option value="55">55</option>
                                <option value="56">56</option>
                                <option value="57">57</option>
                                <option value="58">58</option>
                                <option value="59">59</option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="location-input">
                <table width="100%" border="0">
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <!--<i class="fas fa-map-marker-alt fa-lg"></i>&nbsp;-->
                            <font-awesome-icon :icon="['fas', 'location-dot']" size="lg" class="fontAwesomeIcon" />
                            <b>Location</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button @click="getGeoLocation" style="height:25px;background-color:#087F8C;color:white">&nbsp;Current Location&nbsp;</button>                             
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            <WorldCitySearch @interface="getCitySearchInterface" :textFieldSize="300" :criteria="criteria" @selectedWorldCity="setWorldCity"></WorldCitySearch>&nbsp;                                
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            &nbsp;
                            <b>UTC Offset Minutes</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">    
                            &nbsp;<input 
                                type="text" 
                                size="2"
                                maxlength="5"
                                class="location"
                                placeholder="Mins"
                                style="height:25px;vertical-align:top"
                                v-model="criteria.timeOffsetMinutes"
                                @change="criteria.timeOffset=(new Number(criteria.timeOffsetMinutes)/60.0)"/>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Timezone:</b>&nbsp;<b>{{getUTCString()}}</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="preferences-datetime-add-remove-section">
            <div class="preferences">
                <table width="100%" border="0" class="preferences-input-table">
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Ayanamsa</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="aynamsa" v-model="criteria.ayanamsa" style="float:left;margin:5px;width:300px" @change="getTransitInfo">
                                <option v-for="(index, name) in ayanamsas" :key="name" :value="name">
                                    <pre>{{index}}</pre>
                                </option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Maandi Rising Calculation</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="maandiCalculation" v-model="criteria.maandiCalculationMethod" style="float:left;margin:5px;width:90%;" @change="getTransitInfo">
                                <option value="1">( (Ghatikas of the day/night X a constant rising ghatika of the day/night) &div; 30 )</option>
                                <option value="2">The day/night is divided into 8 equal parts & at the start of Saturn's part </option>
                                <option value="3">The day/night is divided into 8 equal parts & at the middle of Saturn's part </option>
                                <option value="4">The day/night is divided into 8 equal parts & at the end of Saturn's part </option>
                                <option value="5">SUN degree of the given date + a constant rising degree of the day/night</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Sunrise</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="sunrise" v-model="criteria.hinduSunrise" style="float:left;margin:5px" @change="getTransitInfo">
                                <option value="1">Hindu / Vedic</option>
                                <option value="0">Astronomical</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Rahu Node</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="rkn" v-model="criteria.rahuNode" style="float:left;margin:5px" @change="getTransitInfo">
                                <option value="10">Mean Node</option>
                                <option value="11">True Node</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Nutation</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="nutation" v-model="criteria.nutationRequired" style="float:left;margin:5px" @change="getTransitInfo">
                                <option value="1">Include - True Equinox & Obliquity</option>
                                <option value="0">Exclude - Mean Equinox & Obliquity</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">&nbsp;<b>Planetary Positions</b></td>
                    </tr>
                    <tr>
                        <td width="100%" align="left">
                            <select class="month" name="topo" v-model="criteria.topocentricPlanets" style="float:left;margin:5px" @change="getTransitInfo">
                                <option value="0">Geocentric </option>
                                <option value="1">Topocentric</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" align="left"></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>    <!-- End of planetary-positions-wrapper -->
</template>

<script>
import ShowPlanetsTable from '@/components/ShowPlanetsTable'
import MobilePlanetaryPositionsPanchanga from '@/components/MobilePlanetaryPositionsPanchanga'
import HTTP from '../http-axios';
import { getUTCString, isValidLatitude, isValidLongitude, queryString } from '../util.js'
import WorldCitySearch from "./widget-city-search/WorldCitySearch.vue";

export default {
  components: { ShowPlanetsTable, MobilePlanetaryPositionsPanchanga, WorldCitySearch },
    beforeCreate() {
        if ( localStorage.getItem("jktz") ) {
            // A location with timezone already exists
        } else {
            // Clear the existing location data
            // We do not have timezone for existing location
            // Lets start afresh with default location
            // Let the User pick their choice of location
            localStorage.removeItem("jkloc");
            localStorage.removeItem("jklat");
            localStorage.removeItem("jklong");
            localStorage.removeItem("jktimeOffsetMinutes");
            localStorage.removeItem("jktimeOffset");
            localStorage.removeItem("jktz");
        }
    },
  data() {
    return {
        criteria: {
          client: 'MPP',
          deviceType: 'SS',
          location: ( localStorage.getItem("jkloc") ? localStorage.getItem("jkloc") : 'Tiruvannamalai, Tamil Nadu, India'),
          latitude: ( localStorage.getItem("jklat") ? new Number(localStorage.getItem("jklat")) : 12.2253),
          longitude: ( localStorage.getItem("jklong") ? new Number(localStorage.getItem("jklong")) : 79.0747),
          timeOffsetMinutes: ( localStorage.getItem("jktimeOffsetMinutes") ? Number.parseInt(localStorage.getItem("jktimeOffsetMinutes")) : 330),
          timeOffset: ( localStorage.getItem("jktimeOffsetMinutes") ? (new Number(localStorage.getItem("jktimeOffsetMinutes")) / 60.0) : 5.5),
          timezone: ( localStorage.getItem("jktz") ? localStorage.getItem("jktz") : "Asia/Kolkata"),
          year: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getFullYear() : new Date().getFullYear()) ,
          month: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getMonth() + 1 : new Date().getMonth() + 1 ),
          day: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getDate() : new Date().getDate() ),
          hour: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getHours() : new Date().getHours() ),
          minute: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getMinutes() : new Date().getMinutes() ),
          second: ( sessionStorage.getItem("jkdt") ? new Date(sessionStorage.getItem("jkdt")).getSeconds() : new Date().getSeconds() ),
          chartStyle: ( localStorage.getItem("jkchartStyle") ? Number.parseInt(localStorage.getItem("jkchartStyle")) : 0),
          ayanamsa: ( localStorage.getItem("jkayanamsa") ? Number.parseInt(localStorage.getItem("jkayanamsa")) : 1),
          rahuNode: ( localStorage.getItem("jkrkn") ? Number.parseInt(localStorage.getItem("jkrkn")) : 10),
          topocentricPlanets: ( localStorage.getItem("jktopo") ? Number.parseInt(localStorage.getItem("jktopo")) : 0),
          nutationRequired: ( localStorage.getItem("jknutation") ? Number.parseInt(localStorage.getItem("jknutation")) : 1),
          hinduSunrise: ( localStorage.getItem("jksunrise") ? Number.parseInt(localStorage.getItem("jksunrise")) : 1),
          chartTheme: 1,
          chartSize: 2,
          maandiCalculationMethod: ( localStorage.getItem("jkmaandiCalculation") ? Number.parseInt(localStorage.getItem("jkmaandiCalculation")) : 1)
        },
        transitInfo: {},
        ayanamsas: {},
        timeChangeField: 'day',
        jkImageWidth: (window.innerWidth > 600 ? 577 : window.innerWidth * 0.95)
    }
  },
    methods : {
    onResize() {
        this.jkImageWidth = (window.innerWidth > 600 ? 577 : window.innerWidth * 0.9)
    },        
    getUTCString: function() {
      return getUTCString(this.criteria.timeOffsetMinutes);
    },
    isValidLatitude: function() {
      return isValidLatitude(new String(this.criteria.latitude).trim());
    },
    isValidLongitude: function() {
      return isValidLongitude(new String(this.criteria.longitude).trim());
    },
    queryString: function( obj ) {
      return queryString(obj);
    },
    isCriteriaSet: function() {
      return (
        this.isValidLatitude() && 
        this.isValidLongitude() );
    },  
    getAyanamsas: function() {
      HTTP().get(`/api/data/ayanamsas`)
      .then(response => {
        this.ayanamsas = response.data
      })
      .catch(e => {
        console.log(e)
      })
    },
    getTransitInfo: function() {
      if ( this.isCriteriaSet ) {
        HTTP().post(`/api/data/jamakkol?` + this.queryString(this.criteria))
        .then(response => {
          this.transitInfo = response.data
        })
        .catch(e => {
          console.log(e)
        })

        // Capture chart settings 
        localStorage.setItem("jkchartStyle", this.criteria.chartStyle);
        localStorage.setItem("jkayanamsa", this.criteria.ayanamsa)
        localStorage.setItem("jkmaandiCalculation", this.criteria.maandiCalculationMethod)
        localStorage.setItem("jkrkn", this.criteria.rahuNode)
      }
    },
    getCriteriaDate: function() {
      return new Date(this.criteria.year, this.criteria.month-1, this.criteria.day, this.criteria.hour, this.criteria.minute, this.criteria.second);
    },
    setCurrentDateTime: function() {
      var now = new Date();
      this.criteria.year = now.getFullYear();
      this.criteria.month = now.getMonth() + 1;
      this.criteria.day = now.getDate();
      this.criteria.hour = now.getHours();
      this.criteria.minute = now.getMinutes();
      this.criteria.second = now.getSeconds();

      // user chose 'Now' to set to current time
      // remove last used date & time in session storage
      sessionStorage.removeItem("jkdt");

      // Get updated transit info for newly selected time
      this.getTransitInfo()
    },
    updateCriteriaDate: function() {
      this.updateDateTime(this.getCriteriaDate())  
    },
    updateDateTime: function( newDate ) {
        this.criteria.year = newDate.getFullYear()
        this.criteria.month = newDate.getMonth() + 1
        this.criteria.day = newDate.getDate()
        this.criteria.hour = newDate.getHours()
        this.criteria.minute = newDate.getMinutes()
        this.criteria.second = newDate.getSeconds()

        // set latest date & time in session storage
        sessionStorage.setItem("jkdt", this.getCriteriaDate());

        // Check for timeOffset Change
        this.checkTimeOffsetChangeForDate();

        // Get updated transit info for newly selected time
        this.getTransitInfo()
    },
    checkTimeOffsetChangeForDate: function() {
        HTTP().post(`/api/data/city/timeOffset?city=` + this.criteria.location + '&timezone=' + this.criteria.timezone 
                    + '&year=' + this.criteria.year + '&month=' + this.criteria.month + '&day=' + this.criteria.day 
                    + '&hour=' + this.criteria.hour + '&minute=' + this.criteria.minute + '&second=' + this.criteria.second)
        .then(response => {
            var cityTimeOffset = response.data
            // console.log('cityTimeOffset: ' + JSON.stringify(cityTimeOffset))
            if ( cityTimeOffset ) {
                this.criteria.timeOffset = cityTimeOffset
                this.criteria.timeOffsetMinutes = cityTimeOffset * 60.0
                localStorage.setItem("jktimeOffset", this.criteria.timeOffset);
                localStorage.setItem("jktimeOffsetMinutes", this.criteria.timeOffsetMinutes);
            }
        })
        .catch(e => {
            console.log(e)
        })
    },
    update_minute: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setMinutes(newDate.getMinutes() + (addOrRemove));
      this.updateDateTime(newDate)
    },
    update_hour: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setHours(newDate.getHours() + (addOrRemove));
      this.updateDateTime(newDate)
    },
    update_day: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setDate(newDate.getDate() + (addOrRemove));
      this.updateDateTime(newDate)
    },
    update_week: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setDate(newDate.getDate() + (7 * addOrRemove));
      this.updateDateTime(newDate)
    },
    update_month: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setMonth(newDate.getMonth() + (addOrRemove));
      this.updateDateTime(newDate)
    },
    update_year: function (addOrRemove) {
      let newDate = this.getCriteriaDate();
      newDate.setFullYear(newDate.getFullYear() + (addOrRemove));
      this.updateDateTime(newDate)
    },
    updateTimeChange(addOrRemove) {
        switch (this.timeChangeField) {
            case 'minute': this.update_minute(addOrRemove); break
            case 'hour': this.update_hour(addOrRemove); break
            case 'day': this.update_day(addOrRemove); break
            case 'week': this.update_week(addOrRemove); break
            case 'month': this.update_month(addOrRemove); break
            case 'year': this.update_year(addOrRemove); break
        }
    },
    setWorldCity: function (worldCity) {
        this.criteria.location = worldCity.city;
        this.criteria.latitude = worldCity.lat;
        this.criteria.longitude = worldCity.lng;
        this.criteria.timeOffset = worldCity.timeOffset;
        this.criteria.timeOffsetMinutes = worldCity.timeOffsetMinutes;
        this.criteria.timezone = worldCity.timezone;

        // keep this location info in local storage
        localStorage.setItem("jkloc", this.criteria.location);
        localStorage.setItem("jklat", this.criteria.latitude);
        localStorage.setItem("jklong", this.criteria.longitude);
        localStorage.setItem("jktimeOffset", this.criteria.timeOffset);
        localStorage.setItem("jktimeOffsetMinutes", this.criteria.timeOffsetMinutes);
        localStorage.setItem("jktz", this.criteria.timezone);

        // Get updated transit info the newly selected location
        this.getTransitInfo()
    },

    getGeoLocation() {

        if ( 'navigator' in window && 'geolocation' in navigator ) {

            console.log("GeoLocation is supported");
            window.navigator.geolocation.getCurrentPosition(this.geoLocationSuccess, this.geoLocationError);

        } else {
            console.log("GeoLocation is not supported");
            this.getTransitInfo();
        }

    },
    geoLocationSuccess(position) {

        let geoLocationLat = position.coords.latitude
        let geoLocationLng = position.coords.longitude

        if ( geoLocationLat != 0 && geoLocationLng != 0 ) {

            console.log('geoLoctionLatLng: ' + geoLocationLat + ', geoLoctionLng: ' + geoLocationLng);

            HTTP().post(`/api/data/city/geolocation/` + geoLocationLat + '/' + geoLocationLng)
            .then(response => {

            var geoLocationCity = response.data

            if ( geoLocationCity ) {

                this.criteria.location = geoLocationCity.city
                this.criteria.latitude = geoLocationCity.lat
                this.criteria.longitude = geoLocationCity.lng
                this.criteria.timeOffset = geoLocationCity.timeOffset
                this.criteria.timeOffsetMinutes = (new Number(this.criteria.timeOffset) * 60.0)
                this.criteria.timezone = geoLocationCity.timezone

                // keep this location info in local storage
                localStorage.setItem("jkloc", this.criteria.location);
                localStorage.setItem("jklat", this.criteria.latitude);
                localStorage.setItem("jklong", this.criteria.longitude);
                localStorage.setItem("jktimeOffset", this.criteria.timeOffset);
                localStorage.setItem("jktimeOffsetMinutes", this.criteria.timeOffsetMinutes);
                localStorage.setItem("jktz", this.criteria.timezone);

                // Refresh location display on City Search Widget
                this.refreshLocationDisplay()

                // Get transit info for the latest criteria info
                this.getTransitInfo();

            } else {

                alert("Unable to determine your current location. Please try again later.")

                // Get transit info for the latest criteria info
                this.getTransitInfo();

            }

            })
            .catch(e => {

                console.log(e)

                alert("Unable to determine your current location. Please try again later.")                

                // Get transit info for the latest criteria info
                this.getTransitInfo();

            })

        } else {
            alert("Unable to determine your current location. Please try again later.")

            // Get transit info for the latest criteria info
            this.getTransitInfo();

        }

    },
    geoLocationError(err) {

        console.log(`ERROR(${err.code}): ${err.message}`);

        alert("Unable to determine your current location. Please try again later.")

        // Get transit info for the latest criteria info
        this.getTransitInfo();
    },
    setCriteriaLocation() {

        if( localStorage.getItem("jkloc") ) {

            // Returning user. A location is already cookied. Let use it
            console.log('cached-location: ' + localStorage.getItem("jkloc"))

            // Get transit info for the latest criteria info
            this.getTransitInfo();

        } else {
            // New user or cleared cache. Lets get geolocation
            this.getGeoLocation();
        }
    },

    getCitySearchInterface(citySearchInterface) {
        this.$options.citySearchInterface = citySearchInterface;
    },    

    refreshLocationDisplay() {
        this.$options.citySearchInterface.refreshLocation();
    }    

  },
  created() {
    this.getAyanamsas();
    this.setCriteriaLocation();
  },
  mounted() {
    this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
  },

  updated() {
  },
  beforeUnmount() {
    // Capture chart settings before leaving the component
    localStorage.setItem("jkchartStyle", this.criteria.chartStyle);
    localStorage.setItem("jkayanamsa", this.criteria.ayanamsa)
    localStorage.setItem("jkrkn", this.criteria.rahuNode)
    localStorage.setItem("jktopo", this.criteria.topocentricPlanets);
    localStorage.setItem("jknutation", this.criteria.nutationRequired);
    localStorage.setItem("jksunrise", this.criteria.hinduSunrise);

    // set latest date & time in session storage
    sessionStorage.setItem("jkdt", this.getCriteriaDate());

    // keep this location info in local storage
    localStorage.setItem("jkloc", this.criteria.location);
    localStorage.setItem("jklat", this.criteria.latitude);
    localStorage.setItem("jklong", this.criteria.longitude);
    localStorage.setItem("jktimeOffset", this.criteria.timeOffset);
    localStorage.setItem("jktimeOffsetMinutes", this.criteria.timeOffsetMinutes);
    localStorage.setItem("jktz", this.criteria.timezone);

    window.removeEventListener('resize', this.onResize);     
  }

}
</script>

<style scoped>

* {
  font-family: sans-serif; 
}

.pointer {
  cursor: pointer
}

.screen-centered-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}


.planetary-positions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.page-title {
	border: 0px solid;
	width: 100%;
}

.time-change-row {
	border: 0px solid;
	width: 100%;
}

.datetime-location {
  border: 0px solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.preferences-datetime-add-remove-section {
  border: 0px solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preferences {
    margin: auto;
    width: 100%;
    align-content: center;
    margin-left: 10px;
}

.min-hour-day-row {
    border: 0px solid;
    margin: auto;
    width: 100%;
    align-content: center;
}

.week-month-year-row {
    border: 0px solid;
    margin: auto;
    width: 100%;
    align-content: center;
}

.date-time-display {
    margin: auto;
    width: 100%;
    align-content: center;
}

.rasi {
    margin: auto;
    width: 100%;
    align-content: center;
}

.navamsa {
    margin-top:20px;
    width: 100%;
    align-content: center;
}

.date-input {
    margin-top:10px;
    width: 100%;
    align-content: center;
}

.time-input {
    margin-top:10px;
    width: 100%;
    align-content: center;
}

.location-input {
    margin-top:20px;
    width: 100%;
    align-content: center;
}

.datetime-add-remove-section {
    border: 0px solid;
    display:flex;
    justify-content:space-around; 
    width:100%;
    margin-top:10px
}

.planets-details {
    margin-top:10px;    
	padding-top: 20px;
	width: 100%;
}

.panchanga-details {
    margin-top:10px;    
	padding-top: 20px;
	width: 100%;
}

.planets-panchanga-row {
  border: 0px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px;
}

.planets-section {
  margin: 5px;
  width: 80%;
}

.panchanga-section {
  margin: 5px;
  width: 20%;
}

.panchanga-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
 }

.panchanga-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.panchanga-table th {
    padding: 12px 15px;
}

.panchanga-table td {
    padding: 12px 15px;
    color: #344E41;
}

.panchanga-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.panchanga-table tbody tr:nth-of-type(even) {
    background-color: #EDF6F9;
}

.panchanga-table tbody tr:last-of-type {
    border-bottom: 0px solid #7F886A;
}

.panchanga-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

table, td {
    height: 30px;
    padding: 2px;
}

input[type=text] {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;  
  padding: 15px 15px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 2px solid #087F8C;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: none;
}

input[type=text]:focus {
  border: 2px solid #ACD8AA;
}

input[type=number] {
  font-size: 14px;
  text-align: center;
  border-radius: 10px;  
  padding: 12px 12px 12px;
  box-sizing: border-box;
  border: 2px solid #087F8C;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: none;
}

input[type=number]:focus {
  border: 2px solid #ACD8AA;
}

button {
  background-color: #FBFBFF;
  color: #087F8C;
  font-weight: bold;
  border: none;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

select {
  margin: 1px;
  height: 35px;
  cursor:pointer;
  display:inline-block;
  position:relative;
  font:normal 14px;
  color:black;
  border:2px solid  #087F8C;
  border-color: 1px solid #087F8C;
  outline: none;
  border-radius: 10px;  
}

select:focus {
  border: 2px solid #ACD8AA;
}

.fontAwesomeIcon {
  padding-right: 10px;
  pointer-events: none;
}

</style>
