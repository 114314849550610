<template>
	<div class="mobile-logo-display" @click="$router.push('home')">
		<img class="logo-image-style" src="../assets/logo.png" alt="Vedic Astro Chart" />
	</div>
	<nav :style="{ background: background || '#333' }">
			<ul :style="{ background: background || '#333' }" ref="nav">
				<li v-for="(link, index) in navLinks" :key="index">
					<router-link :to="link.path" :style="{ color: linkColor || '#DDD' }" >
						<div class="nav-link-text">{{ link.text }}</div>
						<div class="nav-link-icon">
							<!--<i :class="link.icon"></i>-->
							<font-awesome-icon :icon="link.fawIcon" size="lg" class="fontAwesomeIcon" />
						</div>
					</router-link>
				</li>
				<div class="nav-logo-display" @click="$router.push('home')">
					<img class="logo-image-style" src="../assets/logo.png" alt="Vedic Astro Chart" />
				</div>
			</ul>
	</nav>
</template>

<script>
export default {
	props: ['navLinks', 'background', 'linkColor', 'hoverBackground', 'imagePath'],
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">

.logo-image-style {
	width: 215px;
	height: 61px;
}

.mobile-logo-display {
	display: none;

	a {
		display: inline;
	}

}


.router-link-exact-active {
	color: white;
	background: #FF9F1C;
}

.nav-logo-display {
	border: 0px solid;
	position: absolute;
	top: 0;
	right: 0;
	margin-right: 30px;
	cursor: pointer;
}

nav {
	height: 65px;
	width: 100%;
	ul {
		display: flex;
		height: 100%;
		align-items: center;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;
		box-shadow: 2px 2px 2px 2px #A3B18A;

		a {
			text-decoration: none;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding: 8px;
			border-radius: 10px;
		}

		i {
			margin-right: 10px;
			font-size: 22px;
		}

		li {
			list-style-type: none;
			padding: 0px 12px 0px 12px;
		}
	}

	.fontAwesomeIcon {
		padding-right: 8px;
		vertical-align: middle;
		pointer-events: none;
	}

	.nav-link-text {
		vertical-align: bottom;
	}

}

@media screen and (max-width: 800px) {
	.nav-logo-display {
		display: none;
	}

	.mobile-logo-display {
		display: block;
		align-content: center;
		background-color: #344E41;
	}

	nav {
		height: 65px;
		width: 100%;
		ul {
			display: flex;
			height: 100%;
			align-items: center;
			margin-block-start: 0;
			margin-block-end: 0;
			padding-inline-start: 0;
			box-shadow: 2px 2px 2px 2px #A3B18A;

			a {
				text-decoration: none;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				padding: 2px;
				border-radius: 10px;
			}

			i {
				margin-right: 0px;
				font-size: 20px;
			}

			li {
				list-style-type: none;
				padding: 0px 5px 0px 5px;
			}
		}
	}
	}

@media screen and (max-width: 1375px) {

	.nav-link-text {
		display: none;
	}

	.nav-link-icon {
		border: 0px solid;
		align-content: center;
		text-align: center;
		padding-left: 5px;
	}	
}

@media print {
	nav {
		display: none;
	}
}
</style>
