<template>
	<div class="rasi-amsa-area" v-if="horoscope.criteria">

		<div class="image-title">
			<h2>Rasi - D1</h2>
		</div>

		<div class="rasi-image-area">
			<img :src="horoscope.chartImages.imageMap.D1" alt="D1" :width="chartImageWidth" class="rasi-image">
		</div>

		<div class="image-title">
			<b>{{horoscope.criteria.dateInfo}}</b>
		</div>

		<div class="image-title">
			<h2>Navamsa - D9</h2>
		</div>

		<div class="amsa-image-area">
			<img :src="horoscope.chartImages.imageMap.D9" alt="D9" :width="chartImageWidth" class="amsa-image">
		</div>

		<div class="image-title">
			<b>{{horoscope.criteria.dateInfo}}</b>
		</div>

	</div>
</template>

<script>

import DivisionalChartImage from '@/components/natal-chart/DivisionalChartImage.vue'

export default {
	components: { DivisionalChartImage },	
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
			chartImageWidth: (window.innerWidth > 499 ? 450 : window.innerWidth * 0.9)
		}
	},
	mounted() {
		this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
  	},
	beforeUnmount() {
	    window.removeEventListener('resize', this.onResize);     
  	},	
	methods: {
		onResize() {
			this.chartImageWidth = (window.innerWidth > 499 ? 450 : window.innerWidth * 0.9)
		},        
	}
}
</script>

<style scoped>

.rasi-amsa-area {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.image-title {
	border: 0px solid;
	padding: 20px;
}

.rasi-image-area {
	width: 100%;
}

.amsa-image-area {
	width: 100%;
}

</style>
