<template>
    <WidgetNatalChart :widgetCriteria="widgetCriteria" />
</template>


<script>

import WidgetNatalChart from "@/components/widget-natal-chart/WidgetNatalChart.vue";

export default {
    components: { WidgetNatalChart },
    data() {
        return {
            widgetCriteria: {

                deviceType: "WGT-SG",
                client: 'My Vedic Chart',

                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
                hour: new Date().getHours(),
                minute: new Date().getMinutes(),
                second: 0,

                location: "Tiruvannamalai, Tamil Nadu, India",
                latitude: 12.2253,
                longitude: 79.0747,
                timeOffset: 5.5,
                timeOffsetMinutes: 330,
                timezone: "Asia/Kolkata",

                username: '',
                email: '',
                ipAddress: '',

                chartStyle: 0,
                ayanamsa: 1,
                bhavaChartType: 79,
                dasaBuktiDaysInYear: 365.256,
                aspectDegreeLimit: 15,
                conjunctionDegreeLimit: 20,
                rahuNode: 10,
                topocentricPlanets: 0,
                nutationRequired: 1,
                hinduSunrise: 1,
            },
        };
    },
    created() {
    },
    mounted() {},

    updated() {},

    methods: {
    },
    beforeUnmount() {
    },
};
</script>

<style scoped>

</style>
