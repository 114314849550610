<template>
    <div class="np-screen-wrapper" v-if="horoscope.criteria">
        <div class="chart-area" v-if="horoscope.chartImages">
            <div class="chart-image">
                <div class="chart-image-1">
                    <img :src="horoscope.chartImages.imageMap.D1" alt="D1">
                </div>
                <div class="chart-image-2">
                    <img :src="horoscope.chartImages.imageMap.D9" alt="D9">
                </div>
            </div>
            <div class="chart-image-title">
                {{horoscope.criteria.dateInfo}}
            </div>
        </div>
        <div class="planets-section" align="center" v-if="horoscope.planets">
            <div class="table-title">Planetary Strength</div>
            <div class="aspects-table">
                <table class="styled-table" cellspacing="0" cellpadding="5" border="1">
                    <thead>
                        <tr>
                            <th align="center">&nbsp;&nbsp;</th>
                            <th align="center">SU</th>
                            <th align="center">MO</th>
                            <th align="center">ME</th>
                            <th align="center">VE</th>
                            <th align="center">MA</th>
                            <th align="center">JU</th>
                            <th align="center">SA</th>
                            <th align="center">RA</th>
                            <th align="center">KE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- 1.EXALTED -->
                        <tr>
                            <td><font size="3"><b>Exalted</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.exalted">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 2.MOOLATRIKONA -->
                        <tr>
                            <td><font size="3"><b>Moolatrikona</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.mooltrikona">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 3.OWN HOUSE -->
                        <tr>
                            <td><font size="3"><b>Own House</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.ownHouse">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 4.PARIVARTANA -->
                        <tr>
                            <td><font size="3"><b>Parivartana</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.planetAttributes.parivartana">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 5.VARGOTTAMA -->
                        <tr>
                            <td><font size="3"><b>Vargottama</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.planetAttributes.vargottama">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 6.OWN STAR -->
                        <tr>
                            <td><font size="3"><b>Own Star</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.onOwnStar">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 7.DIG BALA -->
                        <tr>
                            <td><font size="3"><b>Dig Bala</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.digBala">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 8.RETROGRADE -->
                        <tr>
                            <td><font size="3"><b>Retrograde</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.retrograde">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 9.ASTANGA -->
                        <tr>
                            <td><font size="3"><b>Astanga (%)</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.planetAttributes.astangaPercentage > 0">
                                        <td align="center"><font size="4"><b>{{subjectPlanet.planetAttributes.astangaPercentage}}</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!-- 10. DEBILITATED -->
                        <tr>
                            <td><font size="3"><b>Debilitated</b></font></td>
                            <template v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                                <template v-if="subjectPlanet.number > -1 && subjectPlanet.number < 9">
                                    <template v-if="subjectPlanet.debilitated > 0">
                                        <td align="center"><font size="4"><b>&#10003;</b></font></td>
                                    </template>
                                    <template v-else>
                                        <td>&nbsp;</td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ShowPlanetsTable from '@/components/ShowPlanetsTable.vue'

export default {

	components: { ShowPlanetsTable },
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {

	}
}
</script>

<style scoped>

.np-screen-wrapper {
  border: 0px solid;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.chart-area {
	border: 0px solid;
	display: block;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
	margin-top:10px
}

.chart-image-1 {
	padding: 10px;
}

.chart-image-2 {
	padding: 10px;
}


.chart-image-title {
    font-size: 0.9em;
    font-family: sans-serif;
    color: #344E41;
    text-align: left;
    font-weight: bold;
	margin: 5px auto;
}

.aspects-table {
    align-items: center;
    margin: 20px auto;
}

.table-title {
    width: 100%;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: bold;
	margin-top: 20px;
}

.styled-table {
    width: 70%;
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
}

.styled-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
}

.styled-table th {
    border: 1px solid black;
    height: 30px;
    padding: 10px;
}

.styled-table td {
    border: 1px solid black;
    height: 30px;
    color: #344E41;
    padding: 7px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #000000;
}


@media screen and (min-width: 1205px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: row;
	}

	.planets-section {
		margin: 5px;
		width: 80%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 1505px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: row;
	}

	.planets-section {
		margin: 5px;
		width: 65%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 1205px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: column;
	}

	.planets-section {
		margin: 5px;
		width: 90%;
		margin: 20px;
	}

}

</style>
