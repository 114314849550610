<template>
   <div class="panchanga-info">
      <div class="loading-spinner-area" v-show="pageLoading">
         <img class="screen-centered-img" src="../assets/spinner.gif"/>
      </div>
      <div class="anga-selection-area">
         <input type="checkbox" id="yoga" value="Yoga" v-model="selectedAngas">
         <label for="jack">Yoga</label>

         <input type="checkbox" id="tithi" value="Tithi" v-model="selectedAngas">
         <label for="john">Tithi</label>

         <input type="checkbox" id="karana" value="Karana" v-model="selectedAngas">
         <label for="mike">Karana</label>         

         <input type="checkbox" id="nakshatra" value="Nakshatra" v-model="selectedAngas">
         <label for="mike">Nakshatra</label>         

         <input type="checkbox" id="rasi" value="Rasi" v-model="selectedAngas">
         <label for="mike">Rasi</label>         

         <input type="checkbox" id="chandrashtama_rasi" value="Chandrashtama Rasi" v-model="selectedAngas">
         <label for="mike">Chandrashtama</label>         
      </div>
      <div class="summary-table">
         <table class="panchanga-table" width="90%" border="0" v-for="days in panchanga" :key="days">
            <thead>
               <tr>
                  <th colspan="3">{{days[days.length-1].displayLabel}}</th>
               </tr>
            </thead>
            <tbody>
               <template v-for="event in days" :key="event">
               <tr v-if="selectedAngas.includes(event.typeString)">
                  <td align="left" width="30%" v-if="event.current"  style="background:#FFFF66">
                     START<br/>
                     {{event.startWeekDay}}<br/>
                     {{event.startDay}}<br/>
                     <b>{{event.startTime}}</b>
                  </td>
                  <td align="left" width="30%" v-else>
                     START<br/>
                     {{event.startWeekDay}}<br/>
                     {{event.startDay}}<br/>
                     <b>{{event.startTime}}</b>
                  </td>
                  <td align="left"  width="50%" v-if="event.current"  style="background:#FFFF66">
                     {{event.typeString}} 
                     <template v-if="event.currentPercentage >= 0">&nbsp;&nbsp;(<b>{{event.currentPercentage}}%</b>)</template>
                     <br/>
                     <b>{{event.number}} - {{event.name}}</b>
                     <i v-if="event.alternateName"> &nbsp;/&nbsp; {{event.alternateName}}</i><br/>
                     <template v-if="event.lord">Lord: {{event.lord}}</template>
                     <br/>
                     {{event.duration}}&nbsp;&nbsp;({{event.nazhikaDuration}})
                  </td>
                  <td align="left" width="50%" v-else>
                     {{event.typeString}}<br/>
                     <b>{{event.number}} - {{event.name}}</b>
                     <i v-if="event.alternateName"> / {{event.alternateName}}</i><br/>
                     <template v-if="event.lord">Lord: {{event.lord}}</template>
                     <br/>
                     {{event.duration}}&nbsp;&nbsp;({{event.nazhikaDuration}})
                  </td>
                  <td align="left" width="20%" v-if="event.current"  style="background:#FFFF66">
                     END<br/>
                     {{event.endWeekDay}}<br/>
                     {{event.endDay}}<br/>
                     <b>{{event.endTime}}</b>
                  </td>
                  <td align="left" width="20%" v-else>
                     END<br/>
                     {{event.endWeekDay}}<br/>
                     {{event.endDay}}<br/>
                     <b>{{event.endTime}}</b>
                  </td>
               </tr>
            </template>
            </tbody>
         </table>
      </div>
   </div>
</template>
<script>

import HTTP from '../http-axios';
import { queryString } from '../util.js'


export default {
	data() {
		return {
			pageLoading: false,
			panchanga: {}, 
         selectedAngas: (localStorage.getItem("panchangaAngas") ? localStorage.getItem("panchangaAngas").split(",") : ["Yoga", "Tithi", "Karana", "Nakshatra", "Rasi", "Chandrashtama Rasi"]),
			criteria: {
				client: '',
				username: '',
				email: '',
				ipAddress: '',
				deviceType: 'BS',

				year: new Date().getFullYear(),
				month: new Date().getMonth() + 1,
				day: new Date().getDate(),

				hour: new Date().getHours(),
				minute: new Date().getMinutes(),
				second: new Date().getSeconds(),

				timeOffsetMinutes: (-1 * new Date().getTimezoneOffset()),
				timeOffset: ((-1 * new Date().getTimezoneOffset()) / 60.0),

				location: 'geocentric',
				latitude: 0,
				longitude: 0,

				ayanamsa: 1,
				rahuNode: 10,
				topocentricPlanets: false,

				dasaBuktiDaysInYear: 365.256,

				conjunctionDegreeLimit: 20,
				aspectDegreeLimit: 15,

				chartStyle: 1,
				chartTheme: 0,
				chartSize: 0,

            nutationRequired: false,
            hinduSunrise: true,
			},
		}
	},
	methods: {
		queryString: function( obj ) {
			return queryString(obj);
		},
		getPanchanga: function() {
			this.pageLoading = true;
			HTTP().post(`/api/data/panchanga?` + this.queryString(this.criteria))
			.then(response => {
				this.panchanga = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
  	},
	created() {
		this.getPanchanga();
	},
   beforeUnmount() {
      // Capture chart settings before leaving the component
      localStorage.setItem("panchangaAngas", this.selectedAngas)
   }

}
</script>

<style scoped>

.page-title {
	border: 0px solid;
	width: 100%;
}

.screen-centered-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}

.panchanga-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.summary-table {
   margin-top: 20px;
	width: 90%;
	padding: 20px auto;
}

.anga-selection-area {
   margin-top: 20px;
	width: 95%;
	padding: 5px auto;
}

input:checked {
    outline: 2px solid #42b983;
    accent-color: yellow;
}

label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  margin-right: 50px;
  font-size: 14px;
  color: #344E41;
}

.panchanga-table {
    border-collapse: collapse;
    margin: 0px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.panchanga-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.panchanga-table th {
    padding: 15px 20px;
	 font-size: 20px;
}

.panchanga-table td {
    padding: 15px 15px;
    color: #344E41;
}

.panchanga-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
