<template>
    <div class="loading-spinner-area" v-show="pageLoading">
        <img src="../../assets/spinner.gif"/>
    </div>
    <div class="small-screen-wrapper" v-show="!pageLoading">
        <div class="basic-details">
            <ShowClientInfo :criteria="criteria" :horoscope="horoscope" @activeMenuItem="activateMenuItem" />
        </div>
        <div class="vertical-menu">
            <!-- Natal Chart -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('natal-chart')" v-bind:class="{row_selected:activeSection == 'natal-chart',row_not_selected:activeSection != 'natal-chart'}">
                    <td>Natal Chart</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'natal-chart'">
                        <div class="natal-chart-images">
                            <ShowNatalChartImages :horoscope="horoscope" />
                        </div>
                        <div class="planets-details">
                            <template v-if="criteria.topocentricPlanets == 1">
                                <ShowPlanetsTable :horoscope="horoscope" :planetsTitle="'Topocentric Natal Planets'" />
                            </template>
                            <template v-else>
                                <ShowPlanetsTable :horoscope="horoscope" :planetsTitle="'Natal Planets'" />
                            </template>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Divisional Charts -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('divisional-chart')" v-bind:class="{row_selected:activeSection == 'divisional-chart',row_not_selected:activeSection != 'divisional-chart'}">
                    <td>Divisional Chart</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'divisional-chart'">
                        <div class="natal-chart-images">
                            <ShowDivisionalChartImages :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Bhava Chart -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('bhava-chart')" v-bind:class="{row_selected:activeSection == 'bhava-chart',row_not_selected:activeSection != 'bhava-chart'}">
                    <td>Houses</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'bhava-chart'">
                        <div class="bhava-chart-images">
                            <ShowBhavaChart :criteria="criteria" :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Aspects & Conjunctions -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('aspect-conjunction')" v-bind:class="{row_selected:activeSection == 'aspect-conjunction',row_not_selected:activeSection != 'aspect-conjunction'}">
                    <td>Aspects &amp; Conjunctions</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'aspect-conjunction'">
                        <div class="aspects-info">
                            <ShowAspectsInfo :horoscope="horoscope" />
                        </div>
                        <div class="conjunctions-info">
                            <ShowConjunctionsInfo :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Old Vimsottari Dasa -->
            <!--
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('vimsottari-dasa')" v-bind:class="{row_selected:activeSection == 'vimsottari-dasa',row_not_selected:activeSection != 'vimsottari-dasa'}">
                    <td>Old Vimsottari Dasa</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'vimsottari-dasa'">
                        <div class="vimsottari-dasa-details">
                            <ShowVimsottariDasas :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            -->
            <!-- Vimsottari Dasa -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('filtered-vimsottari-dasa')" v-bind:class="{row_selected:activeSection == 'filtered-vimsottari-dasa',row_not_selected:activeSection != 'filtered-vimsottari-dasa'}">
                    <td>Vimsottari Dasa</td>
                </tr>
                <tr>
                    <td v-if="activeSection == 'filtered-vimsottari-dasa'">
                        <div class="vimsottari-dasa-details">
                            <ShowFilteredVimsottariDasas :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Natal Panchanga -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('natal-panchanga')" v-bind:class="{row_selected:activeSection == 'natal-panchanga',row_not_selected:activeSection != 'natal-panchanga'}">
                    <td>Natal Panchanga</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'natal-panchanga'">
                        <div class="panchanga-details">
                            <ShowPanchangaInfo :horoscope="horoscope" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Summary Chart -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('summary-chart')" v-bind:class="{row_selected:activeSection == 'summary-chart',row_not_selected:activeSection != 'summary-chart'}">
                    <td>Basic Chart</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'summary-chart'">
                        <div align="center" class="download-chart">
                            <div align="center">Single Page Chart</div>
                            <br/>
                            <button @click="downloadSummaryPDF()">&nbsp;View Chart&nbsp;</button>
                            <br/><br/>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Complete Chart FREE -->
            <!--
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('detailed-chart')" v-bind:class="{row_selected:activeSection == 'detailed-chart',row_not_selected:activeSection != 'detailed-chart'}">
                    <td>Complete Chart</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'detailed-chart'">
                        <div align="center" class="download-chart" >
                            <div align="center">Complete Natal Chart</div>
                            <br/>
                            <button @click="downloadDetailedPDF()">&nbsp;View Chart&nbsp;</button>
                            <br/><br/>
                        </div>
                    </td>
                </tr>
            </table>
            -->
            <!-- Complete Chart PAID -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr @click="showSection('detailed-chart')" v-bind:class="{row_selected:activeSection == 'detailed-chart',row_not_selected:activeSection != 'detailed-chart'}">
                    <td>Complete Chart</td>
                </tr>
                <tr>
                    <td v-show="activeSection == 'detailed-chart'">
                        <div align="center" class="download-chart" >
                            <MobilePurchaseCompleteNatalChart :criteria="criteria" />
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Donation -->
            <!--
            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                <tr class="row_not_selected">
                    <td>
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="DAJNLMZNENE2Q" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </td>
                </tr>
            </table>
            -->
            <!-- Empty Row -->
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr class="row_not_selected">
                    <td>&nbsp;&nbsp;</td>
                </tr>
            </table>
        </div>
        <!-- Donation -->
        <div align="center" class="donation" v-if="activeSection != 'detailed-chart'">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="DAJNLMZNENE2Q" />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
        </div>
    </div>
</template>

<script>
import { queryString, criteriaToken, generatePaidNatalChartKey } from '@/util.js'
import HTTP from '@/http-axios';
import ApiBaseUrl from '@/http-api-base-url'
import ShowClientInfo from '@/components/small-screen-natal-chart/ShowClientInfo'
import ShowNatalChartImages from '@/components/small-screen-natal-chart/ShowNatalChartImages'
import ShowDivisionalChartImages from '@/components/small-screen-natal-chart/ShowDivisionalChartImages'
import ShowBhavaChart from '@/components/small-screen-natal-chart/ShowBhavaChart'
import ShowPanchangaInfo from '@/components/small-screen-natal-chart/ShowPanchangaInfo'
import ShowPlanetsTable from '@/components/ShowPlanetsTable'
import ShowVimsottariDasas from '@/components/small-screen-natal-chart/ShowVimsottariDasas'
import ShowFilteredVimsottariDasas from '@/components/small-screen-natal-chart/ShowFilteredVimsottariDasas'
import ShowAspectsInfo from '@/components/small-screen-natal-chart/ShowAspectsInfo'
import ShowConjunctionsInfo from '@/components/small-screen-natal-chart/ShowConjunctionsInfo'
import ShowCurrentChartImages from '@/components/small-screen-natal-chart/ShowCurrentChartImages'
import MobilePurchaseCompleteNatalChart from '@/components/small-screen-natal-chart/MobilePurchaseCompleteNatalChart'


export default {
	components: { ShowClientInfo, ShowPanchangaInfo, ShowNatalChartImages, ShowDivisionalChartImages, ShowCurrentChartImages, ShowBhavaChart, 
				  ShowPlanetsTable, ShowAspectsInfo, ShowConjunctionsInfo, ShowVimsottariDasas, ShowFilteredVimsottariDasas, MobilePurchaseCompleteNatalChart },
	props: {
		criteria : {
			type: Object
		}
	},
	data() {
		return {
			activeSection: '',
			horoscope: {},
			pageLoading: false
		}
	},
	created() {
		this.activeSection = ''		
		this.getHoroscope()
	},
	methods: {
		showSection(selectedSection) {
			if ( selectedSection == this.activeSection ) {
				this.activeSection = ''
			} else {
				this.activeSection = selectedSection;	
			}
		},
		ApiBaseUrl: function() {
			return ApiBaseUrl();
		},
        queryString: function (obj) {
            return queryString(obj);
        },
        getCriteriaToken: function() {
            return criteriaToken(this.criteria);
        },
        getPaidNatalChartKey: function() {
            return generatePaidNatalChartKey(this.criteria);
        },
		getHoroscope: function() {
			this.pageLoading = true
			HTTP().post('/api/data/horoscope?' + this.queryString(this.criteria))
			.then(response => {
				this.horoscope = response.data
				this.pageLoading = false
			})
			.catch(e => {
				console.log(e)
			})
		},
		activateMenuItem(activeMenuItem) {
			this.$emit('activeMenuItem', activeMenuItem);
		},
		downloadSummaryPDF() {
			HTTP().get(ApiBaseUrl() + '/api/pdf/horoscope_summary?' + this.queryString(this.criteria) + '&ct=' + this.getCriteriaToken(this.criteria), { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.download = new String("VedicAstroChart-Summary" + (this.criteria.client ? " - " + this.criteria.client : ""))
				link.click()
				URL.revokeObjectURL(link.href)
			}).catch(console.error)
		},		
		downloadDetailedPDF() {
			HTTP().get(ApiBaseUrl() + '/api/pdf/horoscope?' + this.queryString(this.criteria) + '&ct=' + this.getPaidNatalChartKey(this.criteria), { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.download = new String("VedicAstroChart" + (this.criteria.client ? " - " + this.criteria.client : ""))
				link.click()
				URL.revokeObjectURL(link.href)
			}).catch(console.error)
		}		

	}
}
</script>

<style scoped>

.small-screen-wrapper {
	border: 0px solid;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
	align-content: center;
}


.row_not_selected {
	cursor: pointer;
	width: 100%;
	background-color: #344E41; 
	color: #FFFFFF; 
	display: block; 
	padding: 12px; 
	text-align: left;
    font-size: 1.5em;
    font-family: sans-serif;
	margin: 1px;
}

.row_selected {
	cursor: pointer;
	width: 100%;
	background-color: #FF9F1C; 
	color: white;
	font-weight: bold;
	display: block; 
	padding: 12px; 
	text-align: left;
    font-size: 1.5em;
    font-family: sans-serif;
	margin: 1px;
}

.vertical-menu {
	width: 100%; /* Set a width if you like */
}

.basic-details {
	border: 0px solid;
	width: 100%;
	padding-bottom: 20px;
}

.panchanga-details {
	border: 0px solid;
	width: 100%;
}

.natal-chart-images {
	width: 100%;
}

.current-chart-images {
	width: 100%;
}

.bhava-chart-images {
	width: 100%;
}

.planets-details {
	padding-top: 20px;
	width: 100%;
}

.aspects-info {
	width: 100%;
}

.conjunctions-info {
	padding-top: 20px;
	width: 100%;
}

.vimsottari-dasa-details {
	padding-top: 20px;
	width: 100%;
}

.download-chart {
	padding-top: 20px;
	width: 100%;
	font-size: 16px;
	font-family: sans-serif;
	color: #344E41;

}

button {
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  padding: 11px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

.donation {
    margin: 10px;
    padding: 10px;
}

</style>
