<template>
	<div class="screen-wrapper">
        <div class="header-display">Panchanga Information on {{horoscope.criteria.dateInfo}}</div>
        <div class="panchanga-section" align="center" valign="top">
            <!-- Ascendant -->
            <div class="anga">
            <table v-if="horoscope.ascendant" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Ascendant</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.ascendant.zodiacSign}}-<b>{{horoscope.ascendant.zodiacSignName}}</b> / <i>{{horoscope.ascendant.sanskritZodiacName}}</i><br/>
                            Completed: <b>{{horoscope.ascendant.zodiacPercentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.ascendant.zodiacPercentageRemaining}}%</b><br/>
                            Degree: {{horoscope.ascendant.relativeDegreeDMS}}<br/>
                            Ascendant Lord: <b>{{horoscope.ascendant.zodiacLordName}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Rasi -->
            <table v-if="horoscope.rasi" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Rasi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.rasi.zodiacSign}}-<b>{{horoscope.rasi.zodiacSignName}}</b> / <i>{{horoscope.rasi.sanskritZodiacName}}</i><br/>
                            Completed: <b>{{horoscope.rasi.zodiacPercentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.rasi.zodiacPercentageRemaining}}%</b><br/>
                            Degree: {{horoscope.rasi.relativeDegreeDMS}}<br/>
                            Rasi Lord: <b>{{horoscope.rasi.zodiacLordName}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Tithi -->
            <table v-if="horoscope.tithi" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Tithi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.tithi.number}}-<b>{{horoscope.tithi.name}}</b><br/>
                            Completed: <b>{{horoscope.tithi.percentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.tithi.percentageRemaining}}%</b><br/>
                            Degree: {{horoscope.tithi.relativeDegreeDMS}}<br/>
                            Tithi Lord: <b>{{horoscope.tithi.lord}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Karana -->
            <table v-if="horoscope.karana" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Karana</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.karana.number}}-<b>{{horoscope.karana.name}}</b><br/>
                            Completed: <b>{{horoscope.karana.percentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.karana.percentageRemaining}}%</b><br/>
                            Degree: {{horoscope.karana.relativeDegreeDMS}}<br/>
                            Karana Lord: <b>{{horoscope.karana.lord}}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <!-- Yogam -->
            <div class="anga">
            <table v-if="horoscope.yoga" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Yoga</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.yoga.number}}-<b>{{horoscope.yoga.name}}</b><br/>
                            Completed: <b>{{horoscope.yoga.percentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.yoga.percentageRemaining}}%</b><br/>
                            Degree: {{horoscope.yoga.relativeDegreeDMS}}<br/>
                            Yogi Star: <br/><b>{{horoscope.yoga.englishYogiStar}}</b> / <i>{{horoscope.yoga.tamilYogiStar}}</i><br/>
                            Yogi Planet: <b>{{horoscope.yoga.yogiPlanet}}</b><br/>
                            Avayogi Star: <br/><b>{{horoscope.yoga.englishAvayogiStar}}</b> / <i>{{horoscope.yoga.tamilAvayogiStar}}</i><br/>
                            Avayogi Planet: <b>{{horoscope.yoga.avayogiPlanet}}</b><br/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Nakshatra -->
            <table v-if="horoscope.rasi" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Star / Nakshatra</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.rasi.starNumber}}-<b>{{horoscope.rasi.englishStarName}}</b> / <i>{{horoscope.rasi.tamilStarName}}</i><br/> 
                            Completed: <b>{{horoscope.rasi.starPercentageCompleted}}%</b><br/>
                            Remaining: <b>{{horoscope.rasi.starPercentageRemaining}}%</b><br/>
                            Degree: {{horoscope.rasi.starDegreeDMS}}<br/>
                            Star Pada: <b>{{horoscope.rasi.starPada}}</b><br/>
                            Star Lord: <b>{{horoscope.rasi.starLord}}</b><br/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Hora -->
            <table v-if="horoscope.hora" class="planets-table" cellspacing="10" cellpadding="10" align="center" width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left">Hora</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{horoscope.hora.number}}-<b>{{horoscope.hora.name}}</b>,<br/> 
                            Completed: <b>{{horoscope.hora.horaCompletedPercentage}}%</b><br/>
                            Remaining: <b>{{horoscope.hora.horaRemainingPercentage}}%</b><br/>
                            Hora Day: <b>{{horoscope.hora.sunriseWeekday}}</b><br/>
                            Weekday: <b>{{horoscope.criteria.weekday}}</b><br/>
                            Sunrise: <b>{{horoscope.hora.formattedSunrise}}</b><br/> 
                            Hora Start: <b>{{horoscope.hora.formattedStart}}</b><br/> 
                            Hora End:&nbsp; <b>{{horoscope.hora.formattedEnd}}</b><br/>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
	</div>
</template>

<script>

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.screen-wrapper {
  border: 0px solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-display {
  margin-top: 30px;
  margin-bottom: 15px;
  width: 80%;
  font-weight: bold;
}

.panchanga-section {
  margin: 5px;
  width: 80%;
  display: flex;
  justify-content: space-around;
}

.anga {
    width: 45%;
}

.planets-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
 }

.planets-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.planets-table th {
    padding: 12px 15px;
}

.planets-table td {
    padding: 12px 15px;
    color: #344E41;
}

.planets-table tbody tr {
    border-bottom: 0px solid #dddddd;
}

.planets-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.planets-table tbody tr:last-of-type {
    border-bottom: 0px solid #7F886A;
}

.planets-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

</style>
