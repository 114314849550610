<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Jamakkol stands as a distinct manifestation within the realm of predictive arts, rooted in the rich tapestry of Vedic astrology. 
			This specialized method, akin to prasanam or horary astrology, possesses a singular focus: to unveil the mysteries of specific queries through the prism of time.		
		</h3>
	</div>

	<div class="screen-wrapper">

		<div class="desktop-jamakkol-positions" v-if="deviceType=='BS'">
			<Jamakkol />
		</div>

		<div class="mobile-jamakkol-positions" v-if="deviceType=='SS'">
			<MobileJamakkol />
		</div>

	</div>


</template>

<script>
import Jamakkol from '@/components/Jamakkol'
import MobileJamakkol from '@/components/MobileJamakkol'

export default {
	components: { Jamakkol, MobileJamakkol },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

</style>
