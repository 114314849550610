export default ( 
    () => {
        
        // LOCAL:
        //return 'http://192.168.86.41:8081/vac';

        // t4g large - NEW INSTANCE - IP: 3.14.139.24, EIP: 3.14.139.24
        //return 'https://ec2-3-14-139-24.us-east-2.compute.amazonaws.com/vac'

        // HTTP-DOMAIN:
        //return 'http://www.vedicastrochart.com/vac';

        // HTTPS-DOMAIN:
        return 'https://www.vedicastrochart.com/vac';
    }
);