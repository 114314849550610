<template>
	<div class="rasi-amsa-area" v-if="horoscope.criteria">

		<div class="image-title">
			<h2>Rasi - D1</h2>
		</div>

		<div class="rasi-image-area">
			<img :src="horoscope.chartImages.imageMap.D1" alt="D1" class="rasi-image">
		</div>

		<div class="image-title">
			<b>{{horoscope.criteria.dateInfo}}</b>
		</div>

		<div class="image-title">
			<h2>Divisional Charts</h2>
		</div>

		<div class="varga-image-area">
			<DivisionalChartImage 
				:chartImageId="'ssdc'" 
				:criteria="horoscope.criteria" 
				:initialChartType="'D10'" 
				:showChartTypeConfig="true" 
				:chartImageTitle="horoscope.criteria.dateInfo" />
		</div>

		<br/>
		<br/>

	</div>
</template>

<script>

import DivisionalChartImage from '@/components/natal-chart/DivisionalChartImage.vue'

export default {
	components: { DivisionalChartImage },	
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.rasi-amsa-area {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.image-title {
	border: 0px solid;
	padding: 20px;
}

.rasi-image-area {
	width: 100%;
}

.amsa-image-area {
	width: 100%;
}

</style>
