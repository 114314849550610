<template>
	<div class="np-screen-wrapper" v-if="horoscope.criteria">
		<div class="chart-area" v-if="horoscope.chartImages">
			<div class="chart-image">
				<div class="chart-image-1">
					<img :src="horoscope.chartImages.imageMap.D1" alt="D1">
				</div>
				<div class="chart-image-2">
					<img :src="horoscope.chartImages.imageMap.D9" alt="D9">
				</div>	
			</div>
			<div class="chart-image-title">
				{{horoscope.criteria.dateInfo}}
			</div>
		</div>
        <div class="planets-section" align="center" v-if="horoscope.planets">
			<template v-if="horoscope.criteria.topocentricPlanets == 1">
            	<ShowPlanetsTable :horoscope="horoscope" :planetsTitle="'Topocentric Natal Planets'" />
			</template>
			<template v-else>
            	<ShowPlanetsTable :horoscope="horoscope" :planetsTitle="'Natal Planets'" />
			</template>
        </div>
	</div>
</template>

<script>
import ShowPlanetsTable from '@/components/ShowPlanetsTable.vue'

export default {

	components: { ShowPlanetsTable },
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {

	}
}
</script>

<style scoped>

.np-screen-wrapper {
  border: 0px solid;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.chart-area {
	border: 0px solid;
	display: block;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
	margin-top:10px
}

.chart-image-1 {
	padding: 10px;
}

.chart-image-2 {
	padding: 10px;
}


.chart-image-title {
    font-size: 0.9em;
    font-family: sans-serif;
    color: #344E41;
    text-align: left;
    font-weight: bold;
	margin: 5px auto;
}

@media screen and (min-width: 1205px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: row;
	}

	.planets-section {
		margin: 5px;
		width: 80%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 1505px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: row;
	}

	.planets-section {
		margin: 5px;
		width: 75%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 1205px) {

	.chart-image {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: column;
	}

	.planets-section {
		margin: 5px;
		width: 90%;
		margin: 20px;
	}

}

</style>
