<template>
	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Whether you're a seasoned practitioner or a curious novice, our blog series offers a wealth of knowledge about the core concepts and timeless wisdom of Vedic astrology.		
		</h3>
	</div>

	<div class="screen-wrapper">

		<div class="desktop-blogger" v-if="deviceType=='BS'">
			<Blogger />
		</div>

		<div class="mobile-blogger" v-if="deviceType=='SS'">
			<MobileBlogger />
		</div>

	</div>


</template>

<script>
import Blogger from '@/components/Blogger'
import MobileBlogger from '@/components/MobileBlogger'

export default {
	components: { Blogger, MobileBlogger },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-blogger {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-blogger {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

</style>
