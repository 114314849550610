<template>
   <div class="blog-wrapper">
      <div class="blog-post" v-for="blogPost in blogPosts.items" :key="blogPost.id" @click="selectBlogPost(blogPost.selfLink)">
         <div class="blog-post-data">
            <h2 style="text-align:left">{{blogPost.title}}</h2>
         </div>
         <div class="blog-post-image">
            <img :src="blogPost.images[0].url" class="blog-post-image-thumbnail" />
         </div>
      </div>
      <!-- YouTube Demo Video - Creating Vedic Astrology Chart -->
      <div class="blog-post">
         <div class="blog-post-data">
            <h2 style="text-align:left">Creating Vedic Astrology Chart</h2>
            <div style="float:left">
                <iframe width="300" height="169" src="https://www.youtube.com/embed/bpJqXYlLMgY" 
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
            </div>
         </div>
      </div>
      <!-- YouTube Demo Video - Planetary Positions -->
      <div class="blog-post">
         <div class="blog-post-data">
            <h2 style="text-align:left">Vedic Astrology Planetary Positions</h2>
            <div style="float:left">
                <iframe width="300" height="169" src="https://www.youtube.com/embed/kS7a-B2i-GA" 
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>            
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import HTTP from '../http-axios-public';
import {
    dateToMMddyyyy
} from '../util.js'
export default {
    data() {
        return {
            blogId: '279535018966030440',
            bloggerAPIKey: 'AIzaSyB1kuI_u2Olo4rbmHGVKhoN2rrVxpCXhZ0',
            blogPosts: {},
            pageLoading: false,
        }
    },
    created() {
        this.getBlogPosts()
    },
    methods: {
        dateToMMddyyyy: function(dateMillis) {
            return dateToMMddyyyy(dateMillis);
        },
        getBlogPosts: function() {
            this.pageLoading = true;
            HTTP().get('https://www.googleapis.com/blogger/v3/blogs/' + this.blogId + '/posts?key=' + this.bloggerAPIKey + '&fetchImages=true')
                .then(response => {
                    this.blogPosts = response.data
                    this.pageLoading = false
                })
                .catch(e => {
                    console.log(e)
                })
        },
        selectBlogPost(selectedPost) {
            sessionStorage.setItem("lastViewedBlogPost", selectedPost)
            this.$emit('selectedBlogPost', selectedPost)
        }
    },
}
</script>

<style scoped>

.blog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
  border-radius: 10px;
  justify-content: center;
  margin: 20px;
}

.blog-post {
  border: 0px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;  
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #DDD;
}

.blog-post-image {
  border: 0px solid;
  width: 95%;
  padding: 5px;
}

.blog-post-data {
  border: 0px solid;
  width: 95%;
  padding: 5px;
}

.blog-post-image-thumbnail {
  width: 200px;
}

</style>