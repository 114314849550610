<template>
	<div class="panchanga-info" v-if="horoscope.criteria">
		<div class="summary-table">
			<table class="panchanga-table" width="100%" border="0">
				<thead>
					<tr>
						<th>Panchanga</th>
					</tr>
				</thead>
				<tbody>
				<tr>
					<td align="left">
					<font size="4"><b><u>Date Time Location</u></b></font><br/>						
					<b>{{horoscope.criteria.longFormattedDate}}</b><br/>
					{{horoscope.criteria.weekday}}, {{horoscope.criteria.timeOffsetString}}<br/>
					{{horoscope.criteria.location}}<br/>
					Sidereal Time: <b>{{horoscope.sidereal.formattedSiderealTime}}</b><br/>
					Julian Day (UT): <b>{{horoscope.criteria.julianDay}}</b>
					</td>	
				</tr>
				<tr>
					<td align="left">
					<font size="4"><b><u>Ayanamsa</u></b></font><br/>
					<b>{{horoscope.aynamsa.name}}</b><br/>
					Degree: <b>{{horoscope.aynamsa.degreeDMS}}</b><br/>
					Planetary Positions: <b>{{horoscope.criteria.topocentricPlanets == 1 ? 'Topocentric' : 'Geocentric'}}</b></td>	
				</tr>
				<tr>	
					<td align="left">
						<font size="4"><b><u>Ascendant</u></b></font><br/>
						{{horoscope.ascendant.zodiacSign}}-<b>{{horoscope.ascendant.zodiacSignName}}</b> / <i>{{horoscope.ascendant.sanskritZodiacName}}</i><br/>
						Completed: <b>{{horoscope.ascendant.zodiacPercentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.ascendant.zodiacPercentageRemaining}}%</b><br/>
						Degree: {{horoscope.ascendant.relativeDegreeDMS}}<br/>
						Ascendant Lord: <b>{{horoscope.ascendant.zodiacLordName}}</b>
					</td>
				</tr>	
				<tr>
					<td align="left">
						<font size="4"><b><u>Rasi</u></b></font><br/>
						{{horoscope.rasi.zodiacSign}}-<b>{{horoscope.rasi.zodiacSignName}}</b> / <i>{{horoscope.rasi.sanskritZodiacName}}</i><br/>
						Completed: <b>{{horoscope.rasi.zodiacPercentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.rasi.zodiacPercentageRemaining}}%</b><br/>
						Degree: {{horoscope.rasi.relativeDegreeDMS}}<br/>
						Rasi Lord: <b>{{horoscope.rasi.zodiacLordName}}</b>
					</td>
				</tr>
				<tr>
					<td align="left">
						<font size="4"><b><u>Nakshatra</u></b></font><br/>
						{{horoscope.rasi.starNumber}}-<b>{{horoscope.rasi.englishStarName}}</b> / <i>{{horoscope.rasi.tamilStarName}}</i><br/> 
						Completed: <b>{{horoscope.rasi.starPercentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.rasi.starPercentageRemaining}}%</b><br/>
						Degree: {{horoscope.rasi.starDegreeDMS}}<br/>
						Star Pada: <b>{{horoscope.rasi.starPada}}</b><br/>
						Star Lord: <b>{{horoscope.rasi.starLord}}</b><br/>
					</td>
				</tr>
				<tr>	
					<td align="left">
						<font size="4"><b><u>Tithi</u></b></font><br/>
						{{horoscope.tithi.number}}-<b>{{horoscope.tithi.name}}</b><br/>
						Completed: <b>{{horoscope.tithi.percentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.tithi.percentageRemaining}}%</b><br/>
						Degree: {{horoscope.tithi.relativeDegreeDMS}}<br/>
						Tithi Lord: <b>{{horoscope.tithi.lord}}</b>
					</td>
				</tr>
				<tr>	
					<td align="left">
						<font size="4"><b><u>Karana</u></b></font><br/>
						{{horoscope.karana.number}}-<b>{{horoscope.karana.name}}</b><br/>
						Completed: <b>{{horoscope.karana.percentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.karana.percentageRemaining}}%</b><br/>
						Degree: {{horoscope.karana.relativeDegreeDMS}}<br/>
						Karana Lord: <b>{{horoscope.karana.lord}}</b>
					</td>
				</tr>
				<tr>	
					<td align="left">
						<font size="4"><b><u>Yoga</u></b></font><br/>
						{{horoscope.yoga.number}}-<b>{{horoscope.yoga.name}}</b><br/>
						Completed: <b>{{horoscope.yoga.percentageCompleted}}%</b><br/>
						Remaining: <b>{{horoscope.yoga.percentageRemaining}}%</b><br/>
						Degree: {{horoscope.yoga.relativeDegreeDMS}}<br/>
						Yogi Star: <br/><b>{{horoscope.yoga.englishYogiStar}}</b> / <i>{{horoscope.yoga.tamilYogiStar}}</i><br/>
						Yogi Planet: <b>{{horoscope.yoga.yogiPlanet}}</b><br/>
						Avayogi Star: <br/><b>{{horoscope.yoga.englishAvayogiStar}}</b> / <i>{{horoscope.yoga.tamilAvayogiStar}}</i><br/>
						Avayogi Planet: <b>{{horoscope.yoga.avayogiPlanet}}</b><br/>
					</td>
				</tr>
				<tr>	
					<td align="left">
						<font size="4"><b><u>Hora</u></b></font><br/>
						{{horoscope.hora.number}}-<b>{{horoscope.hora.name}}</b>,<br/> 
						Completed: <b>{{horoscope.hora.horaCompletedPercentage}}%</b><br/>
						Remaining: <b>{{horoscope.hora.horaRemainingPercentage}}%</b><br/>
						Hora Day: <b>{{horoscope.hora.sunriseWeekday}}</b><br/>
						Weekday: <b>{{horoscope.criteria.weekday}}</b><br/>
						Start: <b>{{horoscope.hora.formattedStart}}</b><br/> 
						End:&nbsp; <b>{{horoscope.hora.formattedEnd}}</b><br/>
						Sunrise: <b>{{horoscope.hora.formattedSunrise}}</b><br/> 
						Sunset: <b>{{horoscope.hora.formattedSunset}}</b><br/> 
						Next Sunrise: <b>{{horoscope.hora.formattedNextSunrise}}</b><br/> 
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.panchanga-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.summary-table {
	width: 100%;
}

.panchanga-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.panchanga-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.panchanga-table th {
    padding: 12px 15px;
	font-size: 20px;
}

.panchanga-table td {
    padding: 12px 15px;
    color: #344E41;
}

.panchanga-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
