<template>

	<div class="screen-wrapper">

		<div class="chart-criteria" v-if="menuItem == 'home'">
			<TestCriteriaList @criteria="setCriteria" />
		</div>

		<div class="desktop-screen-natal-chart-area" v-if="menuItem !== 'home'">
			<NatalChart :id="'test'" :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

		<div class="small-screen-natal-chart-area" v-if="menuItem !== 'home'">
			<MobileNatalChart :criteria="criteria" @activeMenuItem="activateMenuItem" />
		</div>

	</div>


</template>

<script>
import TestCriteriaList from '@/components/TestCriteriaList'
import NatalChart from '@/components/natal-chart/NatalChart'
import MobileNatalChart from '@/components/small-screen-natal-chart/MobileNatalChart'

export default {
	components: { TestCriteriaList, NatalChart, MobileNatalChart },
	data() {
		return {
			menuItem: 'home',
			criteria: {}
		}
	},
	methods: {
		setCriteria( criteria ) {
			this.criteria = criteria
			this.menuItem = 'charts'
		},		
		activateMenuItem( activeMenuItem ) {
			this.menuItem = activeMenuItem;
		}
	}
}
</script>

<style scoped>

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.chart-criteria {
	border: 0px solid;
	width:100%;
	height: 95%;
	float:left;
	margin-top:0px;
}

.desktop-screen-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.small-screen-natal-chart-area {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

@media screen and (max-width: 1205px) {

	.chart-criteria {
		display: block;
	}

	.desktop-screen-natal-chart-area {
		display: none;
	}

	.small-screen-natal-chart-area {
		display: block;
	}
}

@media screen and (min-width: 1205px) {

	.chart-criteria {
		display: block;
	}

	.desktop-screen-natal-chart-area {
		display: block;
	}

	.small-screen-natal-chart-area {
		display: none;
	}
}


</style>
