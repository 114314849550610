<template>

	<div class="show-planets-wrapper">

		<div class="desktop-planet-table">
			<PlanetsTable :horoscope="horoscope" :planetsTitle="planetsTitle" />
		</div>

		<div class="mobile-planet-table">
			<MobilePlanetsTable :horoscope="horoscope" :planetsTitle="planetsTitle" />
		</div>
		
	</div>


</template>

<script>
import PlanetsTable from '@/components/PlanetsTable'
import MobilePlanetsTable from '@/components/MobilePlanetsTable'

export default {
	components: { PlanetsTable, MobilePlanetsTable },
	props: {
		horoscope : {
			type: Object
		},
        planetsTitle : {
            type: String
        }
	},
	data() {
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>

.show-planets-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-planet-table {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-planet-table {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

@media screen and (max-width: 1205px) {

	.desktop-planet-table {
		display: none;
	}

	.mobile-planet-table {
		display: block;
	}
}

@media screen and (min-width: 1205px) {

	.desktop-planet-table {
		display: block;
	}

	.mobile-planet-table {
		display: none;
	}

}


</style>
