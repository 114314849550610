<template>
	<div class="pdf-wrapper" v-if="criteria">
		<div class="pdf-loading-area" v-show="pdfLoading">
			<img class="screen-centered-img" src="../../assets/spinner.gif"/>
		</div>

		<div class="pdf-area" v-show="!pdfLoading">
			<iframe :src="pdfUrl" width="100%" height="850" style="border: none;" v-on:load="pdfLoaded()">
				This browser does not support PDFs. Please download the PDF to view it: 
					<a
					:href="pdfUrl"
					v-text="'Download PDF'"
					@click.prevent="downloadItem()" />
			</iframe>
		</div>
	</div>
</template>

<script>
import HTTP from '@/http-axios';
import ApiBaseUrl from '@/http-api-base-url'
import { queryString, generatePaidNatalChartKey } from '@/util.js'

export default {
	props: {
		criteria : {
			type: Object
		}
	},
	data() {
		return {
			pdfUrl: "",
			pdfLoading: true
		}
	},
	created() {
		this.pdfUrl =  ApiBaseUrl() + '/api/pdf/horoscope?' + this.queryString(this.criteria) + '&ct=' + this.getCriteriaToken(this.criteria);
	},
	mounted() {
	},
	methods: {
		ApiBaseUrl: function() {
			return ApiBaseUrl();
		},
        queryString: function (obj) {
            return queryString(obj);
        },
        getCriteriaToken: function() {
            return generatePaidNatalChartKey(this.criteria);
        },
		downloadItem () {
			HTTP().get(this.pdfUrl, { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.download = new String("VedicAstroChart" + (this.criteria.client ? " - " + this.criteria.client : ""))
				link.click()
				URL.revokeObjectURL(link.href)
			}).catch(console.error)
		},
		pdfLoaded() {
			this.pdfLoading = false;
		}		
	}
}
</script>

<style scoped>

.pdf-wrapper {
  border: 0px solid;
  width: 100%;
  height: 95%;
  padding-top: 4px;
}

.pdf-loading-area {
	border: 0px solid;
	width:100%;
	height:100%;
}

.pdf-area {
	border: 0px solid;
	width:100%;
	height:100%;
}

.screen-centered-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
}

</style>
