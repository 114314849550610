export function getUTCString( timeOffsetMinutes ) {
    if ( Number.isInteger(Math.abs(timeOffsetMinutes)) ) {
        let utcHH = Number.parseInt(timeOffsetMinutes / 60);
        let utcMM = Number.parseInt(timeOffsetMinutes % 60);
        let utcString = '';

        if ( utcHH < 0  ) {
          utcString = '-' + new String(Math.abs(utcHH)).padStart(2, '0') + ':' + new String(Math.abs(utcMM)).padStart(2, '0');
        } else if ( utcHH > 0 ) {
          utcString = '+' + new String(Math.abs(utcHH)).padStart(2, '0') + ':' + new String(Math.abs(utcMM)).padStart(2, '0');
        } else if ( utcHH == 0 ) {
            if ( utcMM < 0 ) {
              utcString = '-00:' + new String(Math.abs(utcMM)).padStart(2, '0');
            } else {
              utcString = '+00:' + new String(Math.abs(utcMM)).padStart(2, '0');
            }
        }

        return "UTC (" + utcString + ')';
      } else {
        return "UTC(00:00)";
      }
}

export function isValidLatitude(lat) {
    if ( empty(lat) ) {
        return false;
    } else {
        return isFinite(lat) && Math.abs(lat) <= 90;
    }
}

export function isValidLongitude(lng) {
    if ( empty(lng) ) {
        return false;
    } else {
        return isFinite(lng) && Math.abs(lng) <= 180;
    }
}

export function empty(e) {
    switch (e) {
      case "":
      case null:
      case false:
      case "-":  
      case "+":
      case typeof(e) == "undefined":
        return true;
      default:
        return false;
    }
  }
  
export function  queryString( obj ) {
    let str = Object.keys(obj).reduce(function(a, k){
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function dateToMMddyyyy( dateMillis ) {
  var d = new Date(dateMillis);
  var dateString = ("0" + (d.getMonth()+1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + "/" + d.getFullYear();
  return dateString;
}

export function isInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

export function isValidEmail(email) 
{
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}	  

export function isBlank(str) 
{
    return (!str || /^\s*$/.test(str));
}

export function criteriaToken(criteria) {
  if ( criteria ) {
    return ( parseInt(2147483647) - parseInt( Number.parseInt(criteria.year) + Number.parseInt(criteria.month) + Number.parseInt(criteria.day) + Number.parseInt(criteria.latitude) + Number.parseInt(criteria.longitude)));
  } else {
    return parseInt(2147483647);
  }
}

export function generatePaidNatalChartKey(criteria) {
  if ( criteria ) {
    return ( 23 * parseInt( Number.parseInt(criteria.year) + Number.parseInt(criteria.month) + Number.parseInt(criteria.day) + Number.parseInt(criteria.latitude) + Number.parseInt(criteria.longitude)));
  } else {
    return parseInt(2147483647);
  }
}

export default () => {
    getUTCString, 
    isValidLatitude, 
    isValidLongitude, 
    empty, 
    queryString,
    isValidDate,
    dateToMMddyyyy,
    isInteger,
    isValidEmail,
    isBlank,
    criteriaToken,
    generatePaidNatalChartKey
}
