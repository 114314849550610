<template>
    <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is the purpose of this website?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>The purpose of this site is to help to create a Vedic natal / birth chart, find sidereal planetary positions at different times, find transit periods for a sign/star/star divisions, and find direct/retrograde motions and daily Panchanga (Vedic calendar) information.</p>"
          }
        }, {
          "@type": "Question",
          "name": "What are the features included in the natal / birth chart?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Natal chart includes chart Images in North/South/East Indian chart styles, planetary positions, planetary strength, planetary aspects, planetary conjunctions, house cusps and midpoints, divisional Table and 20 divisional charts, 3 levels of Vimsottari Maha Dasa periods, Panchanga (Vedic Calendar) information at the time of birth and more as a PDF document.</p>"
          }
        }, {
          "@type": "Question",
          "name": "Can I use the chart images from this website in my astrology blog or website?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>Yes. Chart images shown on this website are easy to share as HTML images. Right-click on your desired chart image and click 'Copy image address'. Use the copied image address in an HTML image tag.</p>"
          }
        }]
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://www.vedicastrochart.com",
        "logo": "https://www.vedicastrochart.com/android-icon-192x192.png"
      }
      </component>
    </teleport>
</template>

