export default ( 
    () => {

    // DEV:
    //return 'AIzaSyB_GTimWPHnRr6aMnqZl6K-VqYTlsulJ74';

    // PRODUCTION:
    return 'AIzaSyB1kuI_u2Olo4rbmHGVKhoN2rrVxpCXhZ0';

    }
);