<template>
	<div class="screen-wrapper">
		<div class="header-area" v-if="listCriteria">
			<table class="header-table">
				<thead>
					<tr>
						<th>Chart Title</th>
						<th>Age</th>
						<th>Birth Date</th>
						<th>Location</th>
						<th>Chart Date</th>
					</tr>
				</thead>
				<tbody>
				<tr v-for="criteria in listCriteria" @click="setCriteria(criteria)" v-bind:key="criteria.client">
					<td>{{criteria.client}}</td>
					<td align="right">{{criteria.ageInfo}}</td>
					<td align="right">{{criteria.dateInfo}}</td>	
					<td  align="left">{{criteria.location}}, Lat={{criteria.latitude}}, Long={{criteria.longitude}}</td>
					<td>{{new Date().toString().indexOf("(") > 0 ? new Date().toString().substring(0, new Date().toString().indexOf("(")) : new Date().toString()}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

import HTTP from '@/http-axios';

export default {
	data() {
		return {
			listCriteria: null
		}
	},
	created() {
		this.getTestCriteriaList()
	},
	methods: {
		setCriteria(criteria) {
			this.$emit('criteria', criteria)
		},
		getTestCriteriaList: function() {
			HTTP().get(`/api/data/test/list/criteria`)
			.then(response => {
			this.listCriteria = response.data
			})
			.catch(e => {
			console.log(e)
			})
		},

	}
}
</script>

<style scoped>

.screen-wrapper {
  border: 0px solid;
  width: 100%;
}

.header-area {
	border: 0px solid;
	display:flex;
	justify-content:space-around; 
	width:100%;
	margin: 30px auto;
}

.header-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
 }

.header-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.header-table th {
    padding: 12px 15px;
}

.header-table td {
    padding: 12px 15px;
    color: #344E41;
	cursor: pointer;
}

.header-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.header-table tbody tr:nth-of-type(odd) {
    background-color: #EDF6F9;
}

.header-table tbody tr:last-of-type {
    border-bottom: 0px solid #7F886A;
}

.header-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

@media screen and (max-width: 799px) {

}

@media print {
	nav {
		display: none;
	}
}
</style>
