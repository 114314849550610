<template>
   <div class="blog-post-wrapper" v-if="blogPost.content">
      <a @click="backToBlogPosts" title="Back to Blog Posts">
        <span style="margin-top:10px;float:right;cursor:pointer">
            <!--<i class="fab fa-blogger fa-2x" style="color:orange">&nbsp;Home</i>-->
            <h2><font-awesome-icon :icon="['fab', 'blogger']" size="2x" class="fontAwesomeIcon" />Home</h2>
        </span>
      </a>
      <div class="blog-post">
         <div class="blog-post-header">
            <div>
               <a :href="blogPost.url" target="_blank" title="Read this blog at Blogger (Opens new window)">
                  <h2 style="float:left">{{blogPost.title}}</h2>
               </a>
            </div>
         </div>
         <div class="blog-post-content" v-html="blogPost.content">
         </div>
      </div>
   </div>
</template>

<script>
import HTTP from '../http-axios-public';
import {
    dateToMMddyyyy
} from '../util.js'
export default {
    props: {
        selectedBlogPost: {
            type: String
        },
    },
    data() {
        return {
            pageLoading: false,
            blogPost: {},
            bloggerAPIKey: 'AIzaSyB1kuI_u2Olo4rbmHGVKhoN2rrVxpCXhZ0'
        }
    },
    created() {
        this.getBlog()
    },
    methods: {
        dateToMMddyyyy: function(dateMillis) {
            return dateToMMddyyyy(dateMillis);
        },
        getBlog: function() {
            this.pageLoading = true;
            HTTP().get(this.selectedBlogPost + '?key=' + this.bloggerAPIKey)
                .then(response => {
                    this.blogPost = response.data
                    this.pageLoading = false
                })
                .catch(e => {
                    console.log(e)
                })
        },
        backToBlogPosts(selectedPost) {
            sessionStorage.setItem("lastViewedBlogPost", '')
            this.$emit('selectedBlogPost', '')
        }
    },
}
</script>

<style scoped>

.fontAwesomeIcon {
    padding-right: 8px;
    vertical-align: middle;
    pointer-events: none;
}

.blog-post-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;  
  margin: 10px;
}

.blog-post {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 65%;  
  border: 0px solid;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 5px #DDD;
}

.blog-post-header {
  align-content: center;
  width: 100%;  
  display: flex;
  flex-direction: column;
}

.blog-post-content {
  width: 100%;  
  padding: 10px;
}

a {
  display: inline;
}

a:link {
color: #344E41;
}

/* visited link */
a:visited {
color: #344E41;
}

/* mouse over link */
a:hover {
color: #344E41;
}

/* selected link */
a:active {
color: #344E41;
}	


</style>