<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Track planetary transits over Zodiac signs, Nakshatras (lunar mansions), or specific degree on the ecliptic. 
			Whether you're seeking to understand the influence of a particular planet as it traverses a key point in your chart or 
			exploring the broader celestial movements shaping collective energies, our platform offers precision and flexibility. 
		</h3>
	</div>

	<div class="screen-wrapper">
		<div class="desktop-transit" v-if="deviceType=='BS'">
			<Transits />
		</div>

		<div class="mobile-transit" v-if="deviceType=='SS'">
			<MobileTransits />
		</div>
	</div>

</template>

<script>
import Transits from '@/components/Transits'
import MobileTransits from '@/components/MobileTransits'

export default {
	components: { Transits, MobileTransits },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-transit {
	border: 0px solid;
	width: 70%;
	height: 95%;
	margin-top:0px;
}

.mobile-transit {
	border: 0px solid;
	width: 100%;
	height: 95%;
	margin-top:0px;
}

</style>
