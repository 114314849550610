<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Step into the harmonious rhythm of cosmic time with our Panchanga feature, a quintessential Vedic Almanac that unveils the essence of each moment through its five sacred components: 
			Vaara (day of the week), Tithi (lunar phase), Karana (half-day interval), Yoga (auspicious combination of Sun and Moon), and Nakshatra (lunar mansion). 
			Whether you're seeking to plan important events, rituals, or simply align with the natural flow of cosmic energies, our Panchanga offers a comprehensive glimpse into the subtle interplay of cosmic forces. 
		</h3>
	</div>

	<div class="screen-wrapper">
		<div class="desktop-panchanga" v-if="deviceType=='BS'">
			<Panchanga />
		</div>

		<div class="mobile-panchanga" v-if="deviceType=='SS'">
			<MobilePanchanga />
		</div>
	</div>

</template>

<script>
import Panchanga from '@/components/Panchanga'
import MobilePanchanga from '@/components/MobilePanchanga'

export default {
	components: { Panchanga, MobilePanchanga },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-panchanga {
	border: 0px solid;
	width: 70%;
	height: 95%;
	margin-top:0px;
}

.mobile-panchanga {
	border: 0px solid;
	width: 100%;
	height: 95%;
	margin-top:0px;
}

</style>
