<template>
    <div class="conjunction-wrapper">
        <div class="image-area">
            <img :src="horoscope.chartImages.imageMap.D1" alt="D1">
        </div>
        <div class="table-area" v-if="horoscope.planets">
            <div class="table-title">Conjunction</div>
            <div class="conjunction-table">
                <table class="styled-table" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                        <tr>
                            <th align="center">&nbsp;&nbsp;</th>
                            <th align="center">Sun</th>
                            <th align="center">Moon</th>
                            <th align="center">Mercury</th>
                            <th align="center">Venus</th>
                            <th align="center">Mars</th>
                            <th align="center">Jupiter</th>
                            <th align="center">Saturn</th>
                            <th align="center">Rahu</th>
                            <th align="center">Ketu</th>
                            <th align="center" v-if="horoscope.maandiAvailable">Maandi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="subjectPlanet in horoscope.planets.planets" :key="subjectPlanet.number">
                            <template v-if="subjectPlanet.number > -1 && subjectPlanet.number <= 9">
                                <td>{{subjectPlanet.name}}</td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Sun"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Moon"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Mercury"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Venus"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Mars"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Jupiter"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Saturn"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Rahu"]}}</b>
                                </td>
                                <td align="center">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Ketu"]}}</b>
                                </td>
                                <td align="center" v-if="horoscope.maandiAvailable">
                                    <b>{{subjectPlanet.planetAttributes.conjunctions["Maandi"]}}</b>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="conjunction-calc-message">
                <b>Conjunction Degree Range</b>: Conjunction <b>&percnt;</b> numbers are calculated using <b>&#177; {{horoscope.criteria.conjunctionDegreeLimit}}</b> degrees. 
                <br/>'Conjunction Degree Range' value can be changed at Preferences section in Home page.
            </div>

        </div>
    </div>
</template>

<script>

export default {
	props: {
		horoscope : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>

.conjunction-wrapper {
    border: 0px solid;
    width: 80%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-area {
    border: 0px solid;
    width: 50%;
}

.table-area {
    border: 0px solid;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.conjunction-table {
    align-items: center;
    margin: 20px auto;
}

.table-title {
    width: 100%;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: bold;
}

.styled-table {
    width: 70%;
    border-collapse: collapse;
    font-size: 1.0em;
    font-family: sans-serif;
}

.styled-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    font-weight: bold;
}

.styled-table th {
    border: 1px solid black;
    height: 30px;
    padding: 10px;
}

.styled-table td {
    border: 1px solid black;
    height: 30px;
    color: #344E41;
    padding: 5px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #000000;
}

@media screen and (max-width: 1300px) {

	.conjunction-wrapper {
		border: 0px solid;
		display: block;
		display:flex;
		flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 100%;
	}

    .image-area {
        border: 0px solid;
        width: 90%;
        display: flex;
        justify-content: center;
		margin-top:20px
    }

    .table-area {
        border: 0px solid;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
		margin-top:40px
    }

}

</style>
