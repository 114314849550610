import axios from 'axios';

export default ({ requiresAuth = false } = {}) => {
  const options = {};

  if (requiresAuth) {
    options.headers.Authorization = ''
  }
  const instance = axios.create(options);

  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    return Promise.reject(error);
  });
  return instance;
};