<template>
	<div class="bc-screen-wrapper" v-if="horoscope.houseSystems">

		<div class="chart-area">
			<div class="chart-image">
				<div class="chart-image-1" v-if="horoscope.chartImages">
					<img :src="horoscope.chartImages.imageMap.D1" alt="D1">
				</div>
			</div>
			<div class="chart-image-title">
				{{horoscope.criteria.dateInfo}}
			</div>
		</div>

		<br/>
		<h2>{{horoscope.houseSystems[houseSystemReference].houseSystemName}}</h2>
		<br/>

		<span>
			<label>
				<input type="radio" id="one" value="0" v-model="houseSystemReference">
				CUSP
			</label>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<label>
				<input type="radio" id="two" value="1" v-model="houseSystemReference">
				MIDPOINT
			</label>
		</span>
		<br/>

        <div class="planets-section" align="center" v-if="horoscope.houseSystems">
            <ShowBhavaTable :houseSystem="horoscope.houseSystems[houseSystemReference]" />
        </div>

		<br/>
	</div>
</template>

<script>
import ShowBhavaTable from '@/components/natal-chart/ShowBhavaTable.vue'

export default {

	components: { ShowBhavaTable },
	props: {
		criteria : {
			type: Object
		},
		horoscope: {
			type: Object
		}
	},
	data() {
		return {
			houseSystemReference: 0
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.bc-screen-wrapper {
  border: 0px solid;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.chart-area {
	border: 0px solid;
	display: block;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
	margin-top:10px
}

.chart-image {
	border: 0px solid;
	display: block;
	display:flex;
	flex-direction: row;
}

.chart-image-1 {
	padding: 10px;
}

.chart-image-2 {
	padding: 10px;
}

.chart-image-title {
    font-size: 0.9em;
    font-family: sans-serif;
    color: #344E41;
    text-align: left;
    font-weight: bold;
	margin: 5px auto;
}

.planets-section {
  margin: 5px;
  width: 60%;
  margin-bottom: 20px;
}

@media screen and (min-width: 1205px) {

	.planets-section {
		margin: 5px;
		width: 100%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 1505px) {

	.planets-section {
		margin: 5px;
		width: 75%;
		float: left;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 1205px) {

	.planets-section {
		margin: 5px;
		width: 90%;
		margin: 20px;
	}

}

</style>
