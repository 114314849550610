<template>
    <div class="planets-info" v-if="houseSystem">
        <div class="planets-table-area">
            <table class="planets-table" width="100%" border="0">
                <col style="width:100%">
                <thead>
                    <tr>
                        <th align="center">House / Sign<br/> Start / Planets / End <br/> Star <br/>Star Lords</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="house in houseSystem.houses" :key="house.number">
                        <td align="center"  width="100%">
                            <table class="bhava-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td align="center">
                                        <b>{{house.name}}</b>,&nbsp;&nbsp;
                                        <b>{{house.angularVimsottariPeriod.zodiacName}}</b>&nbsp;-&nbsp; 
                                        {{house.angularVimsottariPeriod.zodiacLordName}}
                                    </td>
                                </tr>
                            </table>
                            <table class="bhava-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td width="25%" align="right"><b>Start</b></td>
                                    <td width="50%" align="center">{{house.startDMS}}</td>
                                    <td width="25%" align="left">(0%)</td>
                                </tr>
                            </table>
                            <template v-if="house.planets">
                                <template v-for="planet in house.planets" v-bind:key="planet.number">
                                    <table class="bhava-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td width="25%" align="right"><b>{{planet.name}}</b></td>
                                            <td width="50%" align="center">{{planet.degreeDMS}}</td>
                                            <td width="25%" align="left">({{planet.bhavaPercentage}}%)</td>
                                        </tr>
                                    </table>
                                </template>
                            </template>
                            <table class="bhava-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td width="25%" align="right"><b>End</b></td>
                                    <td width="50%" align="center">{{house.endDMS}}</td>
                                    <td width="25%" align="left">(100%)</td>
                                </tr>
                            </table>
                            <table class="bhava-table" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td align="center"><b>{{house.angularVimsottariPeriod.starAndPada}}</b>&nbsp;/&nbsp;
                                                       <i>{{house.angularVimsottariPeriod.tamilStarName}}-{{house.angularVimsottariPeriod.starPada}}</i></td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        <b>{{house.angularVimsottariPeriod.dasa}}</b>,&nbsp;
                                        {{house.angularVimsottariPeriod.bhukti}},&nbsp;
                                        {{house.angularVimsottariPeriod.antaram}},&nbsp;
                                        {{house.angularVimsottariPeriod.sootchumam}}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</template>

<script>

export default {
	props: {
		houseSystem : {
			type: Object
		}
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style scoped>

.planets-info {
	border: 0px solid;
	display:flex;
	flex-direction: column;
	align-items: center;
	width:100%;
}

.bhava-table {
    border-spacing: 0px;
    line-height: 5px; 
}

.planets-table-area {
	width: 100%;
}

.planets-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
 }

.planets-table thead tr {
    background-color: #ACD8AA;
    color: #344E41;
    text-align: left;
    font-weight: bold;
}

.planets-table th {
    padding: 12px 15px;
	font-size: 20px;
}

.planets-table td {
    padding: 12px 15px;
    color: #344E41;
}

.planets-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

</style>
