<template>
    <div class="admin-city-screen-wrapper">
        <h2>Remove Location Data</h2>
        <div class="admin-city-input-section" align="center">
            <WorldCitySearch :searchMode="'vac'" :textFieldSize="300" :criteria="criteria" @selectedWorldCity="setWorldCity"></WorldCitySearch>                    
        </div>
        <div class="locations">
            <div class="existing-location-data" align="center" v-if="existingLocation.city">
                <table border="1" cellpadding="10" width="100%">        
                    <tr>
                        <td width="25%">Id</td>
                        <td width="75%"><b>{{existingLocation.id}}</b></td>
                    </tr>
                    <tr>
                        <td width="25%">Location</td>
                        <td width="75%"><b>{{existingLocation.city}}</b></td>
                    </tr>
                    <tr>
                        <td width="25%">Latitude</td>
                        <td width="75%"><b>{{existingLocation.lat}}</b></td>
                    </tr>
                    <tr>
                        <td width="25%">Longitude</td>
                        <td width="75%"><b>{{existingLocation.lng}}</b></td>
                    </tr>
                    <tr>
                        <td width="25%">TimeOffset</td>
                        <td width="75%"><b>{{existingLocation.timeOffset}}</b>   </td>
                    </tr>
                    <tr>
                        <td width="25%">TimeZone</td>
                        <td width="75%"><b>{{existingLocation.timezone}}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2" width="100%" align="center">
                            <img :src="getExistingLocationMapUrl()"/>                                                                               
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" width="100%" align="center">
                            <button @click="removeCity()" 
                                style="height:30px;background-color:#087F8C;color:white">&nbsp;Remove this Location&nbsp;</button>                            
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import HTTP from "../http-axios";
import { queryString } from "../util.js";
import GoogleAPICredentials from "../google-api-credentials";
import WorldCitySearch from "@/components/widget-city-search/WorldCitySearch.vue";

export default {
  components: { WorldCitySearch },
  data() {
        return {
            criteria: {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
                hour: new Date().getHours(),
                minute: new Date().getMinutes(),
                second: 0,

                location: "",
                latitude: 0.0,
                longitude: 0.0,
                timeOffset: 0.0,
                timeOffsetMinutes: 0,
            },
            existingLocation: {
                id: 0,
                city: "",
                lat: 0.0,
                lng: 0.0,
                timezone: "",
                timeOffset: 0.0,
            },
        }
  },
  created() {
  },
  mounted() {
  },

  methods: {
        setWorldCity: function (worldCity) {

            // console.log('worldCity: ' + JSON.stringify(worldCity))

            this.existingLocation.id = worldCity.id
            this.existingLocation.city = worldCity.city;
            this.existingLocation.lat = worldCity.lat;
            this.existingLocation.lng = worldCity.lng;
            this.existingLocation.timezone = worldCity.timezone;
            this.existingLocation.timeOffset = worldCity.timeOffset;

            // debug
            // console.log('rwcid: ' + this.existingLocation.id)
            // console.log('rwcloc: ' + this.existingLocation.city)
            // console.log('rwclat: ' + this.existingLocation.lat)
            // console.log('rwclng: ' + this.existingLocation.lng)
            // console.log('rwctz: '  + this.existingLocation.timezone)
            // console.log('rwctoff: ' + this.existingLocation.timeOffset)

        },
        getGoogleAPICredentials: function () {
            return GoogleAPICredentials();
        },
        getExistingLocationMapUrl: function () {
            //return "https://www.google.com/maps/embed/v1/place?q=" + this.newLocation.city + "&zoom=8&maptype=roadmap" + "&key=" + this.getGoogleAPICredentials();
            return 'https://maps.googleapis.com/maps/api/staticmap?center=' + 
                                this.existingLocation.lat +  ',' + this.existingLocation.lng + 
                                '&zoom=10&size=300x300&maptype=roadmap' + 
                                '&markers=size:mid|color:red|' + this.existingLocation.lat +  ',' + this.existingLocation.lng + 
                                '&key=' + this.getGoogleAPICredentials();
        },
        queryString: function (obj) {
            return queryString(obj);
        },
        removeCity: function () {
            HTTP()
                .post(`/api/data/city/remove?` + this.queryString(this.existingLocation))
                .then((response) => {

                    // console.log('success: REMOVE city : ' + JSON.stringify(this.existingLocation))

                    // Clear existing location data
                    this.existingLocation = {};

                    // Search for recently removed location
                    this.getExistingLocations();

                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getExistingLocations: function() {
            HTTP().post(`/api/data/city/lookup?input=` + this.existingLocation.city)
            .then(response => {
                var cities = response.data
                // console.log('cities: ' + JSON.stringify(cities))

                // If uniquely matching city available
                if ( cities && cities.length == 1) {
                    // console.log('cities[0]: ' + JSON.stringify(cities[0]))
                    this.existingLocation.id = cities[0].id
                    this.existingLocation.city = cities[0].city
                    this.existingLocation.lat = cities[0].lat
                    this.existingLocation.lng = cities[0].lng
                    this.existingLocation.timezone = cities[0].timezone
                    this.existingLocation.timeOffset = cities[0].timeOffset
                } else {
                    this.existingLocation = {};
                }

            })
            .catch(e => {
                console.log(e)
            })
        },
  }

}

</script>

<style scoped>

.admin-city-screen-wrapper {
  border: 0px solid;
  width: 720px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.admin-city-input-section {
  border: 0px solid;
  width: 100%;
  margin-top: 10px;
}

.locations {
  width: 100%;
  border: 0px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.new-location-data {
    margin-top: 30px;
    margin-bottom: 15px;
    width: 350px;
}

.existing-location-data {
    margin-top: 30px;
    margin-bottom: 15px;
    width: 350px;
}

table, th, td {
  border: 1px solid #cccccc;
  border-collapse: collapse;
  font-family: sans-serif;
  font-size: 14px;
}

input[type=text] {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;  
  padding: 15px 15px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 2px solid #087F8C;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: none;
}

input[type=text]:focus {
  border: 2px solid #ACD8AA;
}

button {
  background-color: #FBFBFF;
  color: #087F8C;
  font-weight: bold;
  border: none;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  text-align: center;
}

@media screen and (max-width: 725px) {

    .admin-city-screen-wrapper {
        border: 0px solid;
        width: 350px;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .locations {
        border: 0px solid;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .new-location-data {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 350px;
    }

    .existing-location-data {
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 15px;
        width: 350px;
    }

}

</style>