<template>
   <div class="blog-wrapper">
      <div v-if="selectedBlogPost" class="blog-post">
         <BlogPost :selectedBlogPost="selectedBlogPost" @selectedBlogPost="selectBlogPost" />
      </div>
      <div v-else class="blog-posts">
         <BlogPosts @selectedBlogPost="selectBlogPost" />
      </div>
   </div>
</template>

<script>
import BlogPost from '../components/BlogPost.vue'
import BlogPosts from '../components/BlogPosts.vue'
export default {
  components: {
    BlogPost, BlogPosts
  },
	data() {
		return {
      selectedBlogPost:  ( sessionStorage.getItem("lastViewedBlogPost") ? sessionStorage.getItem("lastViewedBlogPost") : ''),
		}
	},
	created() {
	},
	methods: {
    selectBlogPost(selectedPost) {
      this.selectedBlogPost = selectedPost;
    }
	},
}
</script>

<style scoped>

.blog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
  justify-content: center;
}

.blog-posts {
  width: 90%;
}

.blog-post {
  width: 90%;
}

</style>